import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Typography, Tabs, Tab, Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Chip, Grid, CircularProgress } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CrossIcon from '@material-ui/icons/Clear';
import ImageIcon from '@material-ui/icons/Photo';
import DateIcon from '@material-ui/icons/Today';
import QuestionIcon from '@material-ui/icons/Help';
import { fetchOrders } from 'actions/OrderActions';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { statusColours } from 'utils/commonStyles';
import string from 'string';
import _ from 'lodash';
import OrderActionsMenu from 'components/OrderActionsMenu';

var delayTimer;

export class DashboardPage extends React.Component {
  constructor() {
    super();

    this.state = {
      tab: 'yourOrders',
      perPage: 10,
      page: 1,
      searchText: ''
    };

    this.searchOrders = this.searchOrders.bind(this);
    this.handleOnTableChange = this.handleOnTableChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    const { tab, page, perPage } = this.state;
    this.props.fetchOrders(tab, `?per_page=${perPage}&page=${page}`);
  }

  searchOrders() {
    const { tab, page, perPage, searchText } = this.state;
    const { organisation } = this.props;
    let searchQuery = `?per_page=${perPage}&page=${page}`;

    if (searchText != '') {
      const extraQuery = organisation.sites.length > 1 ? `&q[job_name_or_reference_id_or_site_name_or_status_contains]=${searchText}` : `&q[job_name_or_reference_id_or_status_contains]=${searchText}`;
      searchQuery = searchQuery + extraQuery;
    }
    this.props.fetchOrders(tab, searchQuery);
  }

  handleTabChange(_event, value) {
    this.setState({tab: value, searchText: ''}, () => this.props.fetchOrders(value, ''));
  }

  handleOnTableChange(action, tableState) {
    switch (action) {
      case 'search':
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
          // Search again if search term different after short delay
          if ((tableState.searchText || '') != this.state.searchText) {
            this.setState({searchText: tableState.searchText || '', page: tableState.page + 1, perPage: tableState.rowsPerPage}, this.searchOrders);
          }
        }, 250);
        break;
      case 'changePage':
        this.setState({page: tableState.page + 1}, this.searchOrders);
        break;
      case 'changeRowsPerPage':
        this.setState({perPage: tableState.rowsPerPage}, this.searchOrders);
        break;
    }
  }

  orderTableData() {
    const self = this;
    const { organisation } = this.props;

    return this.props.orders.map(function(order) {
      const columns = [
        order.reference_id,
        order.job_name,
        <React.Fragment>
          <Chip label={string(order.status).humanize().s} style={{backgroundColor: statusColours[order.status]}} className="status-chip" />
          {order.status === 'draft' && order.artwork_required &&
            <Tooltip title="Artwork required">
              <ImageIcon className="table-icon" color="error" />
            </Tooltip>
          }
          {order.status === 'draft' && order.activation_date_update_required &&
            <Tooltip title="Activation date has become invalid and requires an update">
              <DateIcon className="table-icon" color="error" />
            </Tooltip>
          }
        </React.Fragment>,
        order.paid ? <DoneIcon /> : <CrossIcon />,
        <OrderActionsMenu order={order} />
      ];

      if (organisation.sites.length > 1) {
        columns.splice(2, 0, order.site.name);
      }

      return columns;
    });
  }

  renderOrderList() {
    const { organisation } = this.props;
    const statusColumn = {
      options: {
        // Custom renderer to add link icon
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <TableCell key={columnMeta.index}>
              Status
              <NavLink to='/process' exact className='button-link'>
                <QuestionIcon className="table-icon" />
              </NavLink>
            </TableCell>
          );
        }
      }
    }
    const columns = [{name: 'Order ID'}, {name: 'Job Name'}, statusColumn, {name: 'Paid'}, {name: 'Actions'}];
    if (organisation.sites.length > 1) columns.splice(2, 0, {name: 'Site'});

    return (
      <MuiThemeProvider theme={(outerTheme) => ({...outerTheme, shadows: Array(25).fill('none'), overrides: {MuiButton: {sizeSmall: {fontSize: 11}}}})}>
        <MUIDataTable
          data={this.orderTableData()}
          columns={columns}
          options={{
                    rowsPerPage: this.state.perPage,
                    page: this.state.page - 1,
                    count: this.props.totalCount,
                    rowsPerPageOptions: [10, 20, 30],
                    selectableRows: false,
                    filter: false,
                    sort: false,
                    download: false,
                    print: false,
                    serverSide: true,
                    viewColumns: false,
                    onTableChange: this.handleOnTableChange
                  }}
        />
      </MuiThemeProvider>
    );
  }

  render() {
    const { currentUser, isFetching } = this.props;

    return (
      <React.Fragment>
        <Typography component="h1" variant="h2" align="center" color="textPrimary">Orders</Typography>
        <Tabs value={this.state.tab} onChange={this.handleTabChange}>
          <Tab label="Your Orders" value="yourOrders" />
          {currentUser.site_requires_approval && currentUser.roles.includes('approver') &&
            <Tab label="Approvals" value="approvals" />
          }
          <Tab label="Completed" value="completed" />
        </Tabs>
        {isFetching ?
          <CircularProgress size={20} className="top-space bottom-space" />
        :
          this.renderOrderList()
        }
        {currentUser.roles.includes('creator') &&
          <NavLink to="/orders/new" className="button-link">
            <Button variant="contained" color="primary">New Order</Button>
          </NavLink>
        }
      </React.Fragment>
    )

  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.session.currentUser,
    order: state.order,
    orders: state.order.orders.collection,
    totalCount: state.order.orders.total_count,
    isFetching: state.order.isFetching,
    isProcessing: state.order.isProcessing,
    organisation: state.session.organisation
  };
}

const mapDispatchToProps = {
  fetchOrders
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
