import React from 'react';
import { connect } from 'react-redux';
import { withStyles} from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { Avatar, Typography, Grid, Table, TableBody, TableRow, TableCell, Switch, Button } from '@material-ui/core';
import UserIcon from '@material-ui/icons/Person';
import { updateDarkMode } from 'actions/SessionActions';

const styles = theme => ({
  title: {
    margin: '20px 0'
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main
  }
});

export class ProfilePage extends React.Component {
  render() {
    const { classes, currentUser, darkMode, updateDarkMode } = this.props;

    return (
      <React.Fragment>
        <Avatar className={classes.avatar}>
          <UserIcon />
        </Avatar>
        <Typography variant='h5' align='center' className={classes.title}>Profile</Typography>
        <Grid container spacing={8} justify='center'>
          <Grid item md={8} xs={12}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>{currentUser.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{currentUser.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Dark Mode</TableCell>
                  <TableCell>
                    <Switch checked={darkMode} onChange={(e, v) => updateDarkMode(v)} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>

        <div className='button-container'>
          <NavLink to='/edit_profile' exact className='button-link nav-link'>
            <Button variant='contained'>Edit Profile</Button>
          </NavLink>
          <NavLink to='/change_password' exact className='button-link nav-link'>
            <Button variant='contained'>Change Password</Button>
          </NavLink>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.session.currentUser,
    darkMode: state.session.darkMode
  };
}

const mapDispatchToProps = {
  updateDarkMode: (enabled) => updateDarkMode(enabled)
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProfilePage));
