import axios from 'axios';
import { push } from 'connected-react-router';
import { flashMessage } from 'redux-flash';
import _ from 'lodash';

export const FETCH_ARTWORKS_REQUEST = 'FETCH_ARTWORKS_REQUEST';
export const FETCH_ARTWORKS_SUCCESS = 'FETCH_ARTWORKS_SUCCESS';
export const FETCH_ARTWORKS_FAILURE = 'FETCH_ARTWORKS_FAILURE';
export const SAVE_ARTWORKS_REQUEST = 'SAVE_ARTWORKS_REQUEST';
export const SAVE_ARTWORKS_SUCCESS = 'SAVE_ARTWORKS_SUCCESS';
export const SAVE_ARTWORKS_FAILURE = 'SAVE_ARTWORKS_FAILURE';

function fetchArtworksRequest() {
  return {
    type: FETCH_ARTWORKS_REQUEST
  }
}

function fetchArtworksSuccess(artworks) {
  return {
    type: FETCH_ARTWORKS_SUCCESS,
    artworks
  }
}

function fetchArtworksFailure() {
  return {
    type: FETCH_ARTWORKS_FAILURE
  }
}

function saveArtworksRequest() {
  return {
    type: SAVE_ARTWORKS_REQUEST
  }
}

function saveArtworksSuccess() {
  return {
    type: SAVE_ARTWORKS_SUCCESS
  }
}

function saveArtworksFailure() {
  return {
    type: SAVE_ARTWORKS_FAILURE
  }
}

export function fetchArtworks() {
  return dispatch => {
    dispatch(fetchArtworksRequest());
    return axios.get('/artworks')
      .then(response => {
        dispatch(fetchArtworksSuccess(response.data.artworks));
      }).catch(error => {
        dispatch(fetchArtworksFailure());
      });
  }
}

export function validateArtwork(data, onSuccess, onFailure) {
  axios.post('/file_uploads', data)
    .then(response => {
      onSuccess(response);
    }).catch(error => {
      onFailure(error);
    });
}

export function saveArtworks(cards, orderId) {
  return dispatch => {
    dispatch(saveArtworksRequest());
    return axios.put('/orders/'+orderId+'/cards', {cards: cards})
      .then(response => {
        dispatch(saveArtworksSuccess());
        if (response.data.can_submit) {
          dispatch(flashMessage('Artworks successfully saved'));
          dispatch(push('/orders/' + response.data.order_id + '/submit'));
        }
        else {
          dispatch(flashMessage('Artwork successfully saved. This order still requires information before it can be submitted'));
          dispatch(push('/orders/' + response.data.order_id));
        }
      }).catch(error => {
        dispatch(saveArtworksFailure());
        dispatch(flashMessage('Artworks cannot be saved, please try again', {isError: true}));
      });
  }
}
