import {
  PROCESS_USER_REQUEST,
  PROCESS_USER_SUCCESS,
  PROCESS_USER_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE
} from 'actions/UserActions';

const initialState = {
  isFetching: false,
  users: [],
  isProcessing: false
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case PROCESS_USER_REQUEST: {
      return Object.assign({}, state, {
        isProcessing: true
      });
    }
    case PROCESS_USER_SUCCESS: {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    case PROCESS_USER_FAILURE: {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    case FETCH_USERS_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        users: []
      });
    }
    case FETCH_USERS_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        users: action.users
      });
    }
    case FETCH_USERS_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        users: []
      });
    }
    case CONTACT_US_REQUEST: {
      return Object.assign({}, state, {
        isProcessing: true
      });
    }
    case CONTACT_US_SUCCESS: {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    case CONTACT_US_FAILURE: {
      return Object.assign({}, state, {
        isProcessing: false
      });
    }
    default:
      return state;
  }
}
