import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Formsy from 'formsy-react';
import CardOrder from 'components/CardOrder';
import { fetchArtworks, saveArtworks } from 'actions/ArtworkActions';
import { fetchOrder } from 'actions/OrderActions';
import { Typography, CircularProgress, Button } from '@material-ui/core';

export class ArtworkForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.onOrderAvailable = this.onOrderAvailable.bind(this);
  }

  componentDidMount() {
    this.props.fetchOrder(this.props.match.params.id, this.onOrderAvailable);
  }

  onOrderAvailable(order){
    this.props.fetchArtworks();
  }

  handleSave() {
    const { order, saveArtworks } = this.props;
    const cards = order.cards;
    saveArtworks(order.cards, order.id);
  }

  render() {
    const { order, artworks, pastOrderArtworks, userArtworks, backDesignArtworks, isFetching, orderIsFetching, organisation, isProcessing } = this.props;

    if (orderIsFetching || isFetching || !order || !order.id) {
      return <CircularProgress />;
    }

    return (
      <React.Fragment>
        <Typography component="h1" variant="h5" align="center" gutterBottom>Add Card Artwork</Typography>
        {order &&
          <Formsy onValidSubmit={this.handleSave} className="full-width">
            {order.cards.map((card, i) =>
              <CardOrder
                key={i}
                artworks={artworks}
                pastOrderArtworks={pastOrderArtworks}
                userArtworks={userArtworks}
                backDesignArtworks={backDesignArtworks}
                sites={organisation.sites}
                card={card}
                order={order}
                cardIndex={i}
              />
            )}
            <div className="button-container">
              <Button type="submit" variant="contained" color="primary" disabled={isProcessing}>Save Artwork</Button>
              <NavLink to="/" className="button-link">
                <Button variant='contained' disabled={isProcessing}>Cancel</Button>
              </NavLink>
            </div>
          </Formsy>
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    order: state.order.orderDetails,
    orderIsFetching: state.order.isFetching,
    isFetching: state.artwork.isFetching,
    artworks: state.artwork.artworks.publicCatalogue,
    isProcessing: state.order.isProcessing,
    pastOrderArtworks: state.artwork.artworks.pastOrderCatalogue,
    userArtworks: state.artwork.artworks.userCatalogue,
    backDesignArtworks: state.artwork.artworks.backDesignsCatalogue,
    organisation: state.session.organisation
  };
}

const mapDispatchToProps = {
  fetchArtworks,
  fetchOrder: (orderId, onSuccess) => fetchOrder(orderId, onSuccess),
  saveArtworks: (cards, orderId) => saveArtworks(cards, orderId)
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtworkForm);
