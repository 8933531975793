import React from 'react';
import { cardLoadAmount, printCharge, cardActivationAmount } from './cardMapping';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid, Typography } from '@material-ui/core';
import Currency from 'react-currency-formatter';
import _ from 'lodash';

export default class CheckoutAmount extends React.Component {
  item(label, amount) {
    return (
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="subtitle2">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" align="right">
            <Currency quantity={amount} currency="AUD"/>
          </Typography>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { card, printLocation, site } = this.props;
    const activationFee = _.get(site, 'activation_fee', 0);

    return (
      <Card>
        <CardContent>
          <Grid container>
            { card.activate_on_site !== '1' && this.item('Card Load', cardLoadAmount(card)) }
            { this.item('Production Fee', printCharge(card, printLocation)) }
            { card.activate_on_site !== '1' && this.item('Activation Fee', cardActivationAmount(card, activationFee)) }
          </Grid>
        </CardContent>
      </Card>
    );
  }
}
