import React from 'react';
import { Button, TextField } from '@material-ui/core';

export const AddComment = (props) => {
  const { order, addCommentToOrder, setComment, comment } = props;

  return (
    <React.Fragment>
      <TextField
        id="comment-field"
        label="Comments"
        multiline
        rows="4"
        placeholder="Add comments here"
        margin="normal"
        variant="outlined"
        fullWidth
        onChange={(e) => setComment(e.target.value)}
      />
      <Button type='submit' variant='contained' color='default'
        onClick={() => addCommentToOrder(order.id, comment)}>Add Comment</Button>
    </React.Fragment>
  );
};
