import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { middleware as flashMiddleware } from 'redux-flash';
import thunkMiddleware from 'redux-thunk';
import createRootReducer from '../reducers';

export const history = createBrowserHistory();
const middlewares = [routerMiddleware(history), flashMiddleware({timeout: false}), thunkMiddleware];

// Enables the Redux DevTools extension to Chrome & Firefox
// See https://github.com/zalmoxisus/redux-devtools-extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  createRootReducer(history),
  composeEnhancers(
    applyMiddleware(
      ...middlewares
    )
  )
);

export default store;
