import {
  ADD_CARD,
  ADD_DENOMINATION,
  UPDATE_DENOMINATION,
  REMOVE_DENOMINATION,
  RESET_STORE,
  UPDATE_CARD,
  REMOVE_CARD,
  UPDATE_ORDER,
  SAVE_DETAILS_REQUEST,
  SAVE_DETAILS_SUCCESS,
  SAVE_DETAILS_FAILURE,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  PROCESS_ORDER_REQUEST,
  PROCESS_ORDER_SUCCESS,
  PROCESS_ORDER_FAILURE,
  DELETE_ORDER_SUCCESS,
  SUBMIT_ORDER_REQUEST,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_FAILURE,
  ADD_COMMENT_TO_ORDER,
  APPROVE_OR_REJECT
} from 'actions/OrderActions';
import {
  SAVE_ARTWORKS_REQUEST,
  SAVE_ARTWORKS_SUCCESS,
  SAVE_ARTWORKS_FAILURE
} from 'actions/ArtworkActions';

import _ from 'lodash';

const initialState = {
  orderDetails: {
    cards: [],
    print_location_id: null
  },
  isFetching: false,
  orders: { collection: [] },
  isProcessing: false
}

export default function order(state = initialState, action) {
  const nextState = _.cloneDeep(state);
  switch (action.type) {
    case ADD_CARD : {
      nextState.orderDetails.cards.push({uid: new Date().getTime(), denominations: [{uid: new Date().getTime(), quantity: null, amount: null, print_amount: null}], activation_date: null, activate_on_site: null})
      return nextState;
    }
    case RESET_STORE: {
      nextState.orderDetails = _.cloneDeep(initialState.orderDetails);
      nextState.orderDetails.cards = [{uid: new Date().getTime(), denominations: [{uid: new Date().getTime(), quantity: null, amount: null, print_amount: null}], activation_date: null, activate_on_site: null}]
      return nextState;
    }
    case UPDATE_CARD : {
      const newCards = state.orderDetails.cards.map((card) => {
        if (card.uid != action.id) return card;
        return Object.assign({}, {...card}, action.attrs);
      });
      nextState.orderDetails.cards = newCards;
      return nextState;
    }
    case REMOVE_CARD : {
      const toRemove = state.orderDetails.cards.find((card) => card.uid == action.id)
      // If has ID, we want to add _destroy flag for Rails to handle nested attributes deletion, otherwise just remove
      if (toRemove.id) {
        const newCards = state.orderDetails.cards.map((card) => {
          if (card.uid != action.id) return card;
          return Object.assign({}, {...card}, {_destroy: true});
        });
        nextState.orderDetails.cards = newCards;
        return nextState;
      } else {
        nextState.orderDetails.cards = state.orderDetails.cards.filter((card) => card.uid != action.id)
        return nextState;
      }
    }
    case ADD_DENOMINATION : {
      const newCards = state.orderDetails.cards.map((card) => {
        if (card.uid != action.id) return card;
        card.denominations.push({uid: new Date().getTime(), amount: null, quantity: null, print_amount: null});
        return card;
      });
      nextState.orderDetails.cards = newCards;
      return nextState;
    }
    case UPDATE_DENOMINATION : {
      const newCards = state.orderDetails.cards.map((card) => {
        if (card.uid != action.cardId) return card;
        const newDenominations = card.denominations.map((denomination) => {
          if (denomination.uid != action.id) return denomination;
          return Object.assign({}, {...denomination}, action.attrs);
        });
        card.denominations = newDenominations;
        return card;
      });
      nextState.orderDetails.cards = newCards;
      return nextState;
    }
    case REMOVE_DENOMINATION : {
      const newCards = state.orderDetails.cards.map((card) => {
        if (card.uid != action.cardId) return card;
        const toRemove = card.denominations.find((denom) => denom.uid == action.id)
        // If has ID, we want to add _destroy flag for Rails to handle nested attributes deletion, otherwise just remove
        if (toRemove.id) {
          const newDenominations = card.denominations.map((denomination) => {
            if (denomination.uid != action.id) return denomination;
            return Object.assign({}, {...denomination}, {_destroy: true});
          });
          card.denominations = newDenominations;
          return card;
        } else {
          card.denominations = card.denominations.filter((d) => d.uid != action.id);
          return card;
        }
      });
      nextState.orderDetails.cards = newCards;
      return nextState;
    }
    case UPDATE_ORDER:{
      nextState.orderDetails[action.key] = action.value;
      return nextState;
    }
    case SAVE_DETAILS_REQUEST: {
      nextState.isProcessing = true;
      return nextState;
    }
    case SAVE_DETAILS_SUCCESS: {
      nextState.isProcessing = false;
      return nextState;
    }
    case SAVE_DETAILS_FAILURE: {
      nextState.isProcessing = false;
      return nextState;
    }
    case FETCH_ORDERS_REQUEST: {
      nextState.isFetching = true;
      return nextState;
    }
    case FETCH_ORDERS_SUCCESS: {
      nextState.isFetching = false;
      nextState.orders = action.orders;
      return nextState;
    }
    case FETCH_ORDERS_FAILURE: {
      nextState.isFetching = false;
      nextState.orders = { collection: [] };
      return nextState;
    }

    case FETCH_ORDER_REQUEST: {
      nextState.isFetching = true;
      nextState.orderDetails = initialState.orderDetails;
      return nextState;
    }

    case FETCH_ORDER_SUCCESS: {
      nextState.isFetching = false;
      nextState.orderDetails = action.order;
      return nextState;
    }

    case FETCH_ORDER_FAILURE: {
      nextState.isFetching = false;
      nextState.orderDetails = initialState.orderDetails;
      return nextState;
    }

    case SAVE_ARTWORKS_REQUEST: {
      nextState.isProcessing = true;
      return nextState;
    }

    case SAVE_ARTWORKS_SUCCESS: {
      nextState.isProcessing = false;
      return nextState;
    }

    case SAVE_ARTWORKS_FAILURE: {
      nextState.isProcessing = false;
      return nextState;
    }

    case PROCESS_ORDER_REQUEST: {
      nextState.isProcessing = true;
      return nextState
    }

    case PROCESS_ORDER_SUCCESS: {
      nextState.isProcessing = false;
      return nextState
    }

    case DELETE_ORDER_SUCCESS: {
      nextState.isProcessing = false;
      const newOrders = state.orders.collection.filter(o => o.id != action.id);
      nextState.orders = {
        collection: newOrders,
        total_count: newOrders.length
      }
      return nextState
    }

    case PROCESS_ORDER_FAILURE: {
      nextState.isProcessing = false;
      return nextState
    }

    case SUBMIT_ORDER_REQUEST: {
      nextState.isProcessing = true;
      return nextState
    }

    case SUBMIT_ORDER_SUCCESS: {
      nextState.isProcessing = false;
      return nextState
    }

    case SUBMIT_ORDER_FAILURE: {
      nextState.isProcessing = false;
      return nextState
    }

    case ADD_COMMENT_TO_ORDER: {
      nextState.orderDetails.comments.push(action.comment);
      return nextState;
    }

    case APPROVE_OR_REJECT: {
      nextState.orderDetails.comments.push(action.comment);
      return nextState;
    }

    default:
      return state;
  }
}
