import React from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Grid, Typography, Button } from '@material-ui/core';
import { withStyles}  from '@material-ui/core/styles';
import TextInput from '../inputs/TextInput';
import { NavLink } from 'react-router-dom';
import { changePassword } from 'actions/UserActions';
import { commonTheme } from 'utils/commonStyles';

export class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    this.props.changePassword(data);
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Formsy onValidSubmit={this.handleSubmit} className={classes.form}>
          <Typography component="h1" variant="h5" align="center" gutterBottom>Change your Password</Typography>
          <Grid container spacing={8} justify="center">
            <Grid item xs={12} md={9}>
              <TextInput type='password' name='current_password' label='Current Password' required
                validations="minLength:8"
                validationErrors={{
                  isDefaultRequiredValue: "can't be blank",
                  minLength: 'must be more than 8 characters'
                }}
              />
              <TextInput type='password' name='password' label='New Password' required
                validations="minLength:8"
                validationErrors={{
                  isDefaultRequiredValue: "can't be blank",
                  minLength: 'must be more than 8 characters'
                }}
              />
              <TextInput type='password' name='password_confirmation' label='New Password Confirmation' required
                validations="minLength:8,equalsField:password"
                validationErrors={{
                  isDefaultRequiredValue: "can't be blank",
                  minLength: 'must be more than 8 characters',
                  equalsField: 'must match password'
                }}
              />
            </Grid>
          </Grid>
          <div className='button-container'>
            <Button type='submit' variant='contained' color='primary'>Change password</Button>
            <NavLink to='/profile' className='button-link'>
              <Button variant='contained'>Cancel</Button>
            </NavLink>
          </div>
        </Formsy>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  changePassword: (data) => changePassword(data)
};

export default withStyles(commonTheme)(connect(null, mapDispatchToProps)(ChangePasswordPage));
