import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import SelectInput from 'components/inputs/SelectInput';

export class ApproverForm extends React.Component {
  render() {
    const { organisation, order, onChange } = this.props;

    const site = organisation.sites.filter(site => site.id === order.site_id)[0];
    const approvalRequired = site ? site.approval_required : null;
    const approvers = site && approvalRequired ? site.approvers : [];

    return (
      <Grid container spacing={8} justify="center" className="bottom-space">
        <Grid item md={6} xs={12}>
          <Typography align='center' paragraph>
            Assign an approver from the dropdown below to confirm your order prior to submission.
            You will receive an email notification once the order has been either approved or rejected.
          </Typography>
          <SelectInput name='approver_id'
            label='Please select an Approver'
            value={order.approver_id}
            onChange={onChange}
            options={approvers.map(approver => { return {value: approver.id, label: approver.full_name}})}
            validations="isExisty"
            validationErrors={{
              isExisty: 'Please select an approver'
            }}
          />
          {approvers.length == 0 &&
            <Typography align='center' color="error">There are no approvers set up. Please contact an administrator to assign a user to approve orders</Typography>
          }
        </Grid>
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  return {
    organisation: state.session.organisation,
    order: state.order.orderDetails
  };
}

export default connect(mapStateToProps, {})(ApproverForm);
