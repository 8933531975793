import React from 'react';
import { totalCardLoadAmount, totalCheckoutAmount, adminFee, totalActivationAmount, totalPrintCharge } from './cardMapping';
import { Grid, Divider, Typography, Card, CardContent, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import Currency from 'react-currency-formatter';
import _ from 'lodash';

export default class TotalCheckoutAmount extends React.Component {
  item(label, amount, enlarge = false, tooltip = null) {
    return (
      <Grid container>
        <Grid item xs={9}>
          <Typography style={{display: 'inline'}} variant={enlarge ? 'subtitle1' : 'subtitle2'}>
            {label}
          </Typography>
          {tooltip &&
            <Tooltip title={tooltip}>
              <HelpIcon className="table-icon" color="primary" />
            </Tooltip>
          }
        </Grid>
        <Grid item xs={3}>
          <Typography variant={enlarge ? 'subtitle1' : 'subtitle2'} align="right">
            <Currency quantity={amount} currency="AUD"/>
          </Typography>
        </Grid>
      </Grid>
    )
  }

  render() {
    const { cards, printLocation, site } = this.props;
    const activationFee = _.get(site, 'activation_fee', 0);

    return (
      <Grid container justify="center" className="top-space">
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              { this.item('Total Card Load', totalCardLoadAmount(cards, printLocation)) }
              { this.item('Total Production Fee', totalPrintCharge(cards, printLocation)) }
              { this.item('Total Activation Fee', totalActivationAmount(cards, activationFee)) }
              {
                adminFee(cards, printLocation, site) > 0 &&
                  this.item('Minimum Order Surcharge', adminFee(cards, printLocation, site), false, `Total production/printing fee must be at least $${site.minimum_production_fee}`)
              }
              { this.item('Delivery Fee', DELIVERY_FEE) }
              <Divider light />
              { this.item('Total Checkout Amount', totalCheckoutAmount(cards, printLocation, activationFee, site), true) }
              <Typography variant="subtitle2" color="textSecondary">
                All amounts exclude GST
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }
}
