import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress } from '@material-ui/core';
import { fetchUsers } from 'actions/UserActions';

export class UsersPage extends React.Component {
  componentDidMount() {
    this.props.fetchUsers();
  }

  renderUserList(currentUser) {
    return (
      <Table className='orders-table'>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Last Sign In</TableCell>
            {currentUser.roles.includes('admin') &&
              <TableCell>Actions</TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.users.map((user, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.full_name}</TableCell>
                <TableCell>{user.last_sign_in_at}</TableCell>
                {currentUser.roles.includes('admin') &&
                  <TableCell>
                    <NavLink to={'/users/' + user.id + '/edit'}>Edit</NavLink>
                  </TableCell>
                }
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  render() {
    const { isFetching, currentUser } = this.props;

    if (isFetching) {
      return (
        <CircularProgress />
      );
    }

    return (
      <React.Fragment>
        <Typography component="h1" variant="h2" align="center" color="textPrimary">Users</Typography>
        {this.renderUserList(currentUser)}
        {currentUser.roles.includes('admin') &&
          <NavLink to="/users/new" className="button-link">
            <Button variant="contained" color="primary">New User</Button>
          </NavLink>
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.session.currentUser,
    isFetching: state.user.isFetching,
    users: state.user.users
  };
}

const mapDispatchToProps = {
  fetchUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
