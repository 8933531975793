import React from 'react';
import { CssBaseline, Paper, Typography } from '@material-ui/core';
import { withStyles}  from '@material-ui/core/styles';
import FlashMessage from 'components/FlashMessage';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
   paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  }
});

export class LoginLayout extends React.Component {
  render() {
    const { children, classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <FlashMessage />
          {children}
        </Paper>
        <Typography variant="caption" align="center" color="textSecondary" className="top-space" paragraph>
          Powered by Waivpay
        </Typography>
      </main>
    )
  }
}

export default withStyles(styles)(LoginLayout);
