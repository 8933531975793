import React from 'react';

export const commonTheme = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  }
});

export const statusColours = {
  'draft': '#e0e0e0',
  'awaiting_approval': '#deb887',
  'submitted': '#f5b911',
  'proofs_confirmed': '#ff7600',
  'shipped': '#6495ed',
  'received': '#0cf5b4',
  'completed': '#27ce27'
};
