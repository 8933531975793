import _ from 'lodash';

function cardQuantity(card) {
  return _.reduce(card.denominations, (sum, denomination) => sum + (parseInt(denomination.quantity) || 0), 0);
}

function printFee(quantity, printLocation) {
  let total = 0;
  printLocation.mappings.forEach(function(fee) {
    if (fee.min_quantity <= quantity && (_.isNil(fee.max_quantity) || fee.max_quantity >= quantity)){
      total = quantity * fee.print_fee;
    }
  })
  return total;
}

export function minQuantity(printLocation) {
  return printLocation.minimum_quantity;
}

export const cardLoadAmount = function(card) {
  if (card.activate_on_site !== '1') {
    return _.reduce(card.denominations, (sum, denomination) => (
      sum + ((denomination.quantity || 0) * (denomination.amount || 0))
    ), 0);
  }
  else {
    return 0;
  }
}

export const totalCardLoadAmount = function(cards) {
  return _.reduce(cards, (sum, card) => sum + cardLoadAmount(card), 0);
}

export const printCharge = function(card, printLocation) {
  return printFee(cardQuantity(card), printLocation);
}

export const totalPrintCharge = function(cards, printLocation) {
  return _.reduce(cards, (sum, card) => sum + printCharge(card, printLocation), 0);
}

export const adminFee = function(cards, printLocation, site) {
  if (!site) {
    return 0;
  }
  else {
    var fee = site.minimum_production_fee - totalPrintCharge(cards, printLocation);
    if (fee > 0) {
      return fee;
    }
    else {
      return 0;
    }
  }
}

export const cardActivationAmount = function(card, activationFee) {
  return card.activate_on_site !== '1' ? cardQuantity(card) * activationFee : 0;
}

export const totalActivationAmount = function(cards, activationFee) {
  return _.reduce(cards, (sum, card) => sum + cardActivationAmount(card, activationFee), 0);
}

export const totalCheckoutAmount = function(cards, printLocation, activationFee, site) {
  let totalCheckoutAmount = totalActivationAmount(cards, activationFee) + adminFee(cards, printLocation, site) + DELIVERY_FEE;
  (cards || []).forEach(function(card) {
    totalCheckoutAmount = totalCheckoutAmount + cardLoadAmount(card) + printCharge(card, printLocation);
  })
  return totalCheckoutAmount;
}
