import axios from 'axios';
import { push } from 'connected-react-router';
import { flashMessage } from 'redux-flash';

export const ACCEPT_INVITE_REQUEST = 'SIGN_IN_REQUEST';
export const ACCEPT_INVITE_SUCCESS = 'SIGN_IN_SUCCESS';
export const ACCEPT_INVITE_FAILURE = 'SIGN_IN_FAILURE';

function acceptInviteRequest() {
  return {
    type: ACCEPT_INVITE_REQUEST
  }
}

function acceptInviteSuccess(user, organisation, config) {
  return {
    type: ACCEPT_INVITE_SUCCESS,
    user,
    organisation,
    config
  }
}

function acceptInviteFailure() {
  return {
    type: ACCEPT_INVITE_FAILURE
  }
}

export function acceptInvite(token, password, passwordConfirmation) {
  return dispatch => {
    dispatch(acceptInviteRequest());
    return axios.put('/invitation', {invite: {invitation_token: token, password: password, password_confirmation: passwordConfirmation}})
      .then(response => {
        dispatch(acceptInviteSuccess(response.data.user, response.data.organisation, response.data.config));
        dispatch(push('/'));
        dispatch(flashMessage('You are now signed in'));
      }).catch(error => {
        dispatch(acceptInviteFailure());
        dispatch(flashMessage('Could not set password', {isError: true}));
      });
  }
}
