import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import { FormHelperText, InputAdornment, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CalendarIcon from '@material-ui/icons/InsertInvitation';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, InlineDatePicker } from 'material-ui-pickers';
import moment from 'moment';

const format = 'DD/MM/YYYY';

export class DateInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: (this.props.value && moment(this.props.value, format)) || null
    };

    this.props.setValue(this.props.value, false);
    this.changeValue = this.changeValue.bind(this);
    this.clearDate = this.clearDate.bind(this);
  }

  changeValue(event) {
    const { onChange, setValue } = this.props;
    if (onChange) onChange(event || '');
    if (event === null) {
      setValue(null);
    } else {
      setValue(event.format(format));
    }
    this.setState({ value: event });
  }

  clearDate(event) {
    event.stopPropagation();
    this.changeValue(null);
  }

  render() {
    const { name, label, hint, getValue, getErrorMessage, isPristine, startDate, endDate } = this.props;
    const errorMessage = getErrorMessage();
    const isError = !isPristine() && errorMessage != null;

    return (
      <div className="input-container">
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <InlineDatePicker
            autoOk
            onlyCalendar
            error={isError}
            id={name}
            name={name}
            label={label}
            value={this.state.value}
            onChange={this.changeValue}
            clearable
            format={format}
            fullWidth
            minDate={startDate || '1900-01-01'}
            maxDate={endDate || '2100-01-01' }
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  {this.state.value ?
                    <IconButton onClick={this.clearDate}>
                      <ClearIcon />
                    </IconButton>
                  :
                    <IconButton>
                      <CalendarIcon />
                    </IconButton>
                  }
                </InputAdornment>
            }}
          />
        </MuiPickersUtilsProvider>
        {!isPristine() && errorMessage &&
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText>{hint}</FormHelperText>
        }
      </div>
    );
  }
}

DateInput.propTypes = {
  ...propTypes
};

export default withFormsy(DateInput);
