import {
  FETCH_CURRENT_USER_REQUEST,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_CURRENT_USER_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  UPDATE_CURRENT_USER_SUCCESS,
  UPDATE_DARK_MODE
} from 'actions/SessionActions';
import { ACCEPT_INVITE_SUCCESS } from 'actions/InvitationActions';
import { RESET_PASSWORD_SUCCESS } from 'actions/UserActions';

const initialState = {
  isFetching: null,
  isProcessing: false,
  currentUser: null,
  organisation: null,
  darkMode: localStorage.getItem('darkMode') == 'true'
};

export default function session(state = initialState, action) {
  switch (action.type) {
    case FETCH_CURRENT_USER_REQUEST: {
      return Object.assign({}, state, {
        isFetching: true,
        currentUser: null,
        organisation: null
      });
    }
    case FETCH_CURRENT_USER_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        currentUser: action.user,
        organisation: action.organisation,
        config: action.config
      });
    }
    case FETCH_CURRENT_USER_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        currentUser: null,
        organisation: null
      });
    }
    case SIGN_IN_REQUEST: {
      return Object.assign({}, state, {
        isProcessing: true,
        currentUser: null,
        organisation: null
      });
    }
    case RESET_PASSWORD_SUCCESS :
    case ACCEPT_INVITE_SUCCESS :
    case SIGN_IN_SUCCESS: {
      return Object.assign({}, state, {
        isProcessing: false,
        currentUser: action.user,
        organisation: action.organisation,
        config: action.config
      });
    }
    case SIGN_IN_FAILURE: {
      return Object.assign({}, state, {
        isProcessing: false,
        currentUser: null,
        organisation: null
      });
    } case SIGN_OUT_SUCCESS: {
      return Object.assign({}, state, {
        currentUser: null
      });
    }
    case SIGN_OUT_FAILURE: {
      return state;
    }
    case UPDATE_CURRENT_USER_SUCCESS: {
      return Object.assign({}, state, {
        isProcessing: false,
        currentUser: action.user
      });
    }
    case UPDATE_DARK_MODE: {
      return Object.assign({}, state, {
        darkMode: action.enabled
      });
    }

    default:
      return state;
  }
}
