import React from 'react';
import { connect } from 'react-redux';
import OrderDetailsForm from './forms/OrderDetailsForm';
import { CircularProgress } from '@material-ui/core';
import { fetchOrder, updateDetails } from 'actions/OrderActions';

export class EditOrderPage extends React.Component {
  constructor(props) {
    super(props);

    props.fetchOrder(props.match.params.order_id)
  }

  render() {
    const { isFetching, orderDetails, updateDetails } = this.props;

    if (isFetching || !orderDetails.id) {
      return <CircularProgress />
    }

    return (
      <OrderDetailsForm title="Edit Card Order" onSubmit={() => updateDetails(orderDetails)} />
    )
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.order.isFetching,
    orderDetails: state.order.orderDetails
  }
}

const mapDispatchToProps = {
  fetchOrder: (orderId) => fetchOrder(orderId),
  updateDetails: (orderDetails) => updateDetails(orderDetails)
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrderPage);
