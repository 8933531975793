import React from 'react';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArtworkGallery from './ArtworkGallery';

const styles = theme => ({
  container: {
    padding: theme.spacing.unit * 4,
    textAlign: 'center'
  }
});

export class Catalogue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange(_event, value) {
    this.setState({tab: value});
  }

  render() {
    const { artworks, pastOrderArtworks, userArtworks, classes, onSelect, organisationAccess, sites, siteId } = this.props;
    const { tab } = this.state;

    return (
      <React.Fragment>
        <AppBar position='static'>
          <Tabs variant="fullWidth" value={tab} onChange={this.handleTabChange}>
            <Tab label='Public Catalogue' />
            <Tab label='User Catalogue' />
            <Tab label='Past Orders' />
          </Tabs>
        </AppBar>
        <div className={classes.container}>
          {tab === 0 && <ArtworkGallery artworks={artworks} onSelect={onSelect} />}
          {tab === 1 && <ArtworkGallery sites={sites} siteId={siteId} organisationAccess={organisationAccess} artworks={userArtworks} onSelect={onSelect} />}
          {tab === 2 && <ArtworkGallery sites={sites} siteId={siteId} organisationAccess={organisationAccess} artworks={pastOrderArtworks} onSelect={onSelect} />}
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Catalogue);
