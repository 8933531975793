import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import _ from 'lodash';
import { InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';

export class SelectInput extends React.Component {
  constructor(props) {
    super(props);

    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    const { onChange, setValue } = this.props;
    if (onChange) onChange(event);
    setValue(event.target.value);
  }

  render() {
    const { name, label, options, disabled, hint, getValue, getErrorMessage, isPristine } = this.props;
    const errorMessage = getErrorMessage();
    const isError = !isPristine() && errorMessage != null;

    return (
      <div className="input-container">
        <InputLabel htmlFor={name} error={isError}>{label}</InputLabel>
        <Select
          error={isError}
          id={name}
          name={name}
          onChange={this.changeValue}
          value={getValue() || ''}
          disabled={disabled}
          fullWidth
        >
          <MenuItem value="" disabled></MenuItem>
          {options.map((option, i) => (
            _.isString(option) ?
              <MenuItem key={i} value={option}>{option}</MenuItem>
            :
              <MenuItem key={i} value={option.value}>{option.label}</MenuItem>
          ))}
        </Select>
        {!isPristine() && errorMessage &&
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText>{hint}</FormHelperText>
        }
      </div>
    );
  }
}

SelectInput.propTypes = {
  ...propTypes
};

export default withFormsy(SelectInput);
