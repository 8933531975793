import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { updateCurrentUser } from 'actions/SessionActions';
import UsersForm from 'components/pages/forms/UsersForm';

export class UpdateDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    this.props.updateCurrentUser(data);
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography component="h1" variant="h5" align="center" gutterBottom>Edit Profile</Typography>
        <UsersForm handleSubmit={this.handleSubmit} roles={false} submitLabel="Edit Profile" cancelRoute={"/profile"} />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  updateCurrentUser: (data) => updateCurrentUser(data)
};

export default connect(null, mapDispatchToProps)(UpdateDetailsPage);
