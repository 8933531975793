import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { AppRoute } from '../AppRoute';
import { fetchCurrentUser } from 'actions/SessionActions';
import { CircularProgress } from '@material-ui/core';
import LoginLayout from '../layouts/LoginLayout';
import LoginPage from '../pages/LoginPage';
import AcceptInvitePage from '../pages/AcceptInvitePage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import Router from '../Router';

export class HomePage extends React.Component {
  constructor(props) {
    super(props);

    props.fetchCurrentUser();
  }

  render() {
    if (this.props.isFetching == null || this.props.isFetching) {
      return <CircularProgress />;
    }

    return (
      <Switch>
        <AppRoute exact path='/login' layout={LoginLayout} component={LoginPage} />
        <AppRoute exact path='/users/invitation/accept' layout={LoginLayout} component={AcceptInvitePage} />
        <AppRoute exact path='/forgot_password' layout={LoginLayout} component={ForgotPasswordPage} />
        <AppRoute exact path='/password/edit' layout={LoginLayout} component={ResetPasswordPage} />
        <Route component={Router} />
      </Switch>
    )
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.session.isFetching
  };
}

const mapDispatchToProps = {
  fetchCurrentUser
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));
