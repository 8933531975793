import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Formsy from 'formsy-react';
import { Typography, Button } from '@material-ui/core';
import { withStyles }  from '@material-ui/core/styles';
import TextInput from '../inputs/TextInput';
import { sendResetInstruction } from 'actions/UserActions';
import { commonTheme } from 'utils/commonStyles';

export class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.props.navigateTo('/');
    }
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    this.props.sendResetInstruction(data.email);
  }

  render() {
    const { classes, navigateTo } = this.props;

    return (
      <React.Fragment>
        <img src={require('../../images/card-track.png')} className='full-width bottom-space' />
        <Formsy onValidSubmit={this.handleSubmit} className={classes.form}>
          <Typography component='h1' variant='h5' align='center'>Forgot Password?</Typography>

          <TextInput name='email' label='Email' required
            validationErrors={{isDefaultRequiredValue: "can't be blank"}}
          />

          <div className='button-container'>
            <Button fullWidth type='submit' variant='contained' color='primary'>Reset Password</Button>
            <Typography variant='body2' className="top-space cursor" onClick={() => navigateTo('/login')}>Cancel</Typography>
          </div>
        </Formsy>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.session.currentUser
  };
}

const mapDispatchToProps = {
  navigateTo: (path) => push(path),
  sendResetInstruction: (email) => sendResetInstruction(email)
};

export default withStyles(commonTheme)(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage));
