import React from 'react';
import { Modal, Typography, List, ListItem, ListItemIcon, ListItemText, Button } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/Check';
import CrossIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  paper: {
    position: 'absolute',
    top: '5em',
    left: '25%',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    maxHeight: '80%',
    overflow: 'scroll'
  },
  image: {
    width: '100%'
  }
});

const ListItemContent = (props) => {
  return (
    <ListItem>
      <ListItemIcon>
        {props.cross ?
          <CrossIcon color="secondary" />
        :
          <CheckBoxIcon color="secondary" />
        }
      </ListItemIcon>
      <ListItemText>{props.text}</ListItemText>
    </ListItem>
  );
}

export class ArtworkGuidelineModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({open: true});
  }

  handleClose() {
    this.setState({open: false});
  }

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        <Typography>
          <a href="javascript:void(0)" onClick={this.handleOpen}>See artwork specifications</a>
        </Typography>
        <Modal open={open} onClose={this.handleClose}>
          <div className={classes.paper}>
            <Typography variant="h5" align="center">
              Artwork Specifications
            </Typography>
            <img src={require('../images/artwork_example.png')} className={classes.image} />
            <List>
              <ListItemContent text="Image file formats only. PNG, JPG and JPEG file types recommended" />
              <ListItemContent text="Dimensions for images must be 1075 x 709 pixels (91 x 60mm)" />
              <ListItemContent text="High Resolution at 300DPI" />
              <ListItemContent text="No Eftpos logo on artwork (our system will add for you)" cross />
              <ListItemContent text="No guidelines or crop marks on artwork (our system will add for you)" cross />
            </List>
            <div className="center">
              <Button variant="contained" color="primary" onClick={this.handleClose}>OK</Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ArtworkGuidelineModal);
