import axios from 'axios';
import { push } from 'connected-react-router';
import { flashMessage } from 'redux-flash';
import { processUserRequest, processUserSuccess, processUserFailure } from './UserActions.js';

export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE';
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';
export const UPDATE_CURRENT_USER_SUCCESS = 'UPDATE_CURRENT_USER_SUCCESS';
export const UPDATE_DARK_MODE = 'UPDATE_DARK_MODE';

function fetchCurrentUserRequest() {
  return {
    type: FETCH_CURRENT_USER_REQUEST
  }
}

function fetchCurrentUserSuccess(user, organisation, config) {
  return {
    type: FETCH_CURRENT_USER_SUCCESS,
    user,
    organisation,
    config
  }
}

function fetchCurrentUserFailure() {
  return {
    type: FETCH_CURRENT_USER_FAILURE
  }
}

function signInRequest() {
  return {
    type: SIGN_IN_REQUEST
  }
}

function signInSuccess(user, organisation, config) {
  return {
    type: SIGN_IN_SUCCESS,
    user,
    organisation,
    config
  }
}

function signInFailure() {
  return {
    type: SIGN_IN_FAILURE
  }
}

export function signOutSuccess() {
  return {
    type: SIGN_OUT_SUCCESS
  }
}

function signOutFailure() {
  return {
    type: SIGN_OUT_FAILURE
  }
}

function updateCurrentUserSuccess (user) {
  return {
    type: UPDATE_CURRENT_USER_SUCCESS,
    user
  }
}

function  updateDarkModeRequest(enabled) {
  return {
    type: UPDATE_DARK_MODE,
    enabled
  }
}

export function fetchCurrentUser() {
  return dispatch => {
    dispatch(fetchCurrentUserRequest());
    return axios.get('/session')
      .then(response => {
        dispatch(fetchCurrentUserSuccess(response.data.user, response.data.organisation, response.data.config));
      }).catch(error => {
        dispatch(fetchCurrentUserFailure());
      });
  }
}

export function signInUser(email, password) {
  return dispatch => {
    dispatch(signInRequest());
    return axios.post('/session', {email: email, password: password})
      .then(response => {
        dispatch(signInSuccess(response.data.user, response.data.organisation, response.data.config));
        dispatch(flashMessage('You are now signed in'));
        dispatch(push('/'));
      }).catch(error => {
        dispatch(signInFailure());
        dispatch(flashMessage('Sign in failed, please try again', {isError: true}));
        dispatch(push("/login"));
      });
  }
}

export function signOutUser() {
  return dispatch => {
    return axios.delete('/session')
      .then(response => {
        dispatch(signOutSuccess());
        dispatch(flashMessage('You have been logged out'));
        dispatch(push('/login'));
      }).catch(error => {
        dispatch(signOutFailure());
      });
  }
}

export function updateCurrentUser(data) {
  return dispatch => {
    dispatch(processUserRequest())
    return axios.put('/session', {data})
      .then(response => {
        dispatch(processUserSuccess())
        dispatch(updateCurrentUserSuccess(response.data.user))
        dispatch(flashMessage('Your profile has been updated'));
        dispatch(push('/profile'));
      }).catch(error => {
        dispatch(processUserFailure())
        dispatch(flashMessage('Could not change user profile', {isError: true}));
      });
  }
}

export function updateDarkMode(enabled) {
  return dispatch => {
    localStorage.setItem('darkMode', enabled);
    dispatch(updateDarkModeRequest(enabled));
  }
}
