import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { Button, Typography, TextField, Grid } from '@material-ui/core';
import { addCommentToOrder, markAsApprovedOrRejected } from 'actions/OrderActions';
import { AddComment } from 'components/AddComment';

export class ApproveOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: ''
    }
  }

  setComment = (comment) => {
    this.setState({ comment });
  };

  render() {
    const { order, addCommentToOrder, markAsApprovedOrRejected } = this.props;

    return (
      <React.Fragment>
        <Grid container justify="center">
          <Grid item md={8} xs={12}>
            <Formsy>
              <Typography variant='h5' align='center'>
                Approve Order
              </Typography>

              <AddComment
                order={order}
                addCommentToOrder={addCommentToOrder}
                setComment={this.setComment}
                comment={this.state.comment}
              />

              <div className="button-container">
                <Button type='submit' variant='contained' color='primary'
                  onClick={() => markAsApprovedOrRejected(order.id, 'approved', this.state.comment)}>Approve</Button>
                <Button type='submit' variant='contained' color='secondary'
                  onClick={() => markAsApprovedOrRejected(order.id, 'rejected', this.state.comment)}>Reject</Button>
              </div>
            </Formsy>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    order: state.order.orderDetails
  }
}

const mapDispatchToProps = {
  addCommentToOrder: (orderId, comment) => addCommentToOrder(orderId, comment),
  markAsApprovedOrRejected: (orderId, status, comment) => markAsApprovedOrRejected(orderId, status, comment)
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveOrder);
