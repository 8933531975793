import _ from 'lodash';
import {
  FETCH_ARTWORKS_REQUEST,
  FETCH_ARTWORKS_SUCCESS,
  FETCH_ARTWORKS_FAILURE

} from 'actions/ArtworkActions'

const initialState = {
  isFetching: false,
  artworks: { publicCatalogue: [], pastOrderCatalogue: [], userCatalogue: [], backDesignsCatalogue: []},
}

 export default function artwork(state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTWORKS_REQUEST : {
      return Object.assign({}, state, {
        isFetching: true,
        artworks: { publicCatalogue: [], pastOrderCatalogue: [], userCatalogue: [], backDesignsCatalogue: [] }
      });
    }

    case FETCH_ARTWORKS_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        artworks: { publicCatalogue: action.artworks.public_catalogue, pastOrderCatalogue: action.artworks.past_order_catalogue, userCatalogue: action.artworks.user_catalogue, backDesignsCatalogue: action.artworks.back_designs }
      });
    }

    case FETCH_ARTWORKS_FAILURE: {
      return Object.assign({}, state, {
        isFetching: false,
        artworks: { publicCatalogue: [], pastOrderCatalogue: [], userCatalogue: [], backDesignsCatalogue: [] }
      });
    }

    default:
      return state;
  }
}
