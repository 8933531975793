import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Divider, Table, TableBody, TableRow, TableCell, Button } from '@material-ui/core';
import _ from 'lodash';
import ArtworkModal from './ArtworkModal';
import BackDesignModal from './BackDesignModal';
import ArtworkGuidelineModal from './ArtworkGuidelineModal';
import FileInput from './inputs/FileInput';
import Currency from 'react-currency-formatter';
import { updateCard } from 'actions/OrderActions';
import classnames from 'classnames';

const CARD_TOP_MARGIN = '10px';

const styles = {
  list: {
    marginTop: '45px'
  },
  card: {
    position: 'relative',
    margin: '0 auto'
  },
  cardImage: {
    marginTop: CARD_TOP_MARGIN
  },
  overlayText: {
    position: 'absolute',
    top: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    color: 'grey',
    fontSize: '20px'
  },
  logoOnCard: {
    position: 'absolute',
    top: CARD_TOP_MARGIN,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0
  },
  logoButton: {
    border: '2px solid black',
    borderRadius: 10,
    height: 53,
    padding: '5px 15px',
    cursor: 'pointer'
  },
  whiteLogo: {
    backgroundColor: 'black'
  },
  title: {
    marginTop: '20px',
    textTransform: 'uppercase',
    fontSize: '14px'
  },
  cardBackImage: {
    marginTop: CARD_TOP_MARGIN
  },
  selectedLogo: {
    border: '3px solid red'
  },
  cardHeading: {
    textAlign: 'center',
    marginTop: '1em'
  },
  instructionText: {
    fontSize: '14px',
    color: 'grey',
    marginBottom: '10px',
    lineHeight: '1.5'
  },
  backInstruction: {
    marginTop: '20px'
  }
};

export class CardOrder extends React.Component {
  constructor(props) {
    super(props);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleSelectArtwork = this.handleSelectArtwork.bind(this);
  }

  handleUpload(blobKey, url) {
    const { card, updateCard } = this.props;
    updateCard(card.id, {artworkBlobKey: blobKey, image: url});
  }

  handleSelectArtwork(attrs) {
    const { card, updateCard } = this.props;
    updateCard(card.id, attrs);
  }

  handleEftposToggle(cardId, value) {
    this.props.updateCard(cardId, {eftposLogo: value});
  }

  renderEftposLogoButtons() {
    const { classes, card, currentSite } = this.props;

    return (
      <React.Fragment>
        <Typography component='h1' variant='subtitle1' className={classes.title} gutterBottom>Select Eftpos Colour</Typography>
        <Grid container spacing={8} justify="space-around">
          {Object.keys(window.EFTPOS_LOGOS).map((key) =>
            <Grid item xs={4} key={key}>
              <img
                src={require('../images/eftpos_logos/button_'+ window.EFTPOS_LOGOS[key])}
                className={classnames(classes.logoButton, window.EFTPOS_LOGOS[key] == 'white.png' ? classes.whiteLogo : '', card.eftposLogo == key ? classes.selectedLogo : '')}
                onClick={(e) => this.handleEftposToggle(card.id, key)} />
            </Grid>
          )}
          {currentSite && currentSite.allow_no_eftpos_logo &&
            <Grid item xs={4}>
              <img src={require('../images/eftpos_logos/button_none.png')} className={classes.logoButton} onClick={(e) => this.handleEftposToggle(card.id, null)} />
            </Grid>
          }
          <Typography component='h1' variant='subtitle1' className={classes.instructionText}>
            Please note, eftpos logo must be clearly visible and unobstructed on your card artwork. Failure to do this will result in eftpos not providing approval for your card to proceed.
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }

  renderCardSummary() {
    const { card, classes, order, sites } = this.props;
    const site = _.find(sites, ['id', order.site_id]);

    return (
      <React.Fragment>
        <Grid item md={6} xs={12}>
          <Table>
            <TableBody>
              {site && !site.corporate_customer &&
                <TableRow>
                  <TableCell>Activate Via GiVVPOS?</TableCell>
                  <TableCell>{card.activate_on_site === '1' ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              }
              {card.activate_on_site !== '1' &&
                <TableRow>
                  <TableCell>Activation Date</TableCell>
                  <TableCell>{card.activation_date || 'Blank'}</TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </Grid>
        <Grid item md={6} xs={12}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Denominations</TableCell>
                <TableCell>
                  {card.denominations.map((denomination, index) => (
                    <div key={index}>
                      {denomination.display}
                    </div>
                  ))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </React.Fragment>
    )
  }

  render() {
    const { artworks, pastOrderArtworks, userArtworks, backDesignArtworks, card, order, classes, sites, cardIndex } = this.props;

    return (
      <React.Fragment>
        <Typography component='h1' variant='h6' className={classes.cardHeading}>Card {cardIndex + 1}</Typography>
        <Grid container spacing={8} align='center'>
          {this.renderCardSummary()}
          <Grid item md={6} xs={12}>
            <div className={classes.card}>
              <img src={card.image} className={classnames(classes.cardImage, 'card-image')} />
              {!card.image && <Typography className={classes.overlayText}>Select Front Design</Typography>}
              {_.includes(Object.keys(window.EFTPOS_LOGOS), String(card.eftposLogo)) &&
                <img src={require('../images/eftpos_logos/card_' + window.EFTPOS_LOGOS[card.eftposLogo])} className={classnames(classes.logoOnCard, 'logo-on-card')} />
              }
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <img src={card.backDesignImage} className={classnames(classes.cardBackImage, 'card-image')} />
          </Grid>
          <Grid item md={6} xs={12}>
            <ArtworkModal sites={sites} siteId={order.site_id} artworks={artworks} pastOrderArtworks={pastOrderArtworks} userArtworks={userArtworks} buttonLabel='Select Front Design' onSelect={this.handleSelectArtwork} />
            <FileInput name={'new_artwork_' + card.id} buttonLabel='Upload Front Design' extraParams={{print_location_id: order.print_location.id}} onChange={this.handleUpload} />
            <ArtworkGuidelineModal />
          </Grid>
          <Grid item md={6} xs={12}>
            <BackDesignModal backDesigns={backDesignArtworks} cardId={card.id} buttonLabel='Select Back Design' onSelect={this.handleSelectArtwork} />
            <Typography component='h1' variant='subtitle1' className={classnames(classes.instructionText, classes.backInstruction)}>The highlighted panels will be printed with your specifications</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={8} align='center'>
          <Grid item md={6} xs={12}>
            { this.renderEftposLogoButtons() }
          </Grid>
        </Grid>
        <Divider className='card-divider' />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentSite: state.session.organisation.sites.find((site) => site.id === state.session.currentUser.site_id)
});

const mapDispatchToProps = {
  updateCard: (id, attrs) => updateCard(id, attrs),
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CardOrder));
