import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import HomePage from 'components/pages/HomePage';

export class ThemeProvider extends React.Component {
  getMuiTheme() {
    const { darkMode, currentUser } = this.props;

    return createMuiTheme({
      typography: { useNextVariants: true },
      palette: { type: currentUser && darkMode ? 'dark' : 'light' },
      a: { color: 'red'}
    })
  }

  render() {
    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <HomePage />
      </MuiThemeProvider>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.session.currentUser,
    darkMode: state.session.darkMode
  };
}

export default withRouter(connect(mapStateToProps, null)(ThemeProvider));
