import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Formsy from 'formsy-react';
import { Typography, Button } from '@material-ui/core';
import { withStyles}  from '@material-ui/core/styles';
import TextInput from 'components/inputs/TextInput';
import { contactUs } from 'actions/UserActions';
import { commonTheme } from 'utils/commonStyles';

const requiredValidation = {isDefaultRequiredValue: "can't be blank"};

export class ContactUsPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    this.props.contactUs(data.phone_number, data.message);
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Formsy onValidSubmit={this.handleSubmit} className={classes.form}>
          <Typography component='h1' variant='h5' align='center'>Contact Us</Typography>
          <TextInput type='number' name='phone_number' label='Phone Number' hint='Optional'
            validationErrors={requiredValidation}
          />
          <TextInput name='message' label='Message' required multiline
            validationErrors={requiredValidation}
          />
          <div className='button-container'>
            <Button type='submit' variant='contained' color='primary' disabled={this.props.isProcessing}>Submit</Button>
            <NavLink to='/' className='button-link'>
              <Button variant='contained'>Cancel</Button>
            </NavLink>
          </div>
        </Formsy>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    isProcessing: state.user.isProcessing
  };
}

const mapDispatchToProps = {
  contactUs: (phone_number, message) => contactUs(phone_number, message)
};

export default withStyles(commonTheme)(connect(mapStateToProps, mapDispatchToProps)(ContactUsPage));
