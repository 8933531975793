import React from 'react';
import { Typography } from '@material-ui/core';
import renderHTML from 'react-render-html';

export default class CustomPage extends React.Component {
  render() {
    const { content, name } = this.props;

    return (
      <React.Fragment>
        <Typography variant='h5' align='center'>{name}</Typography>
        <Typography variant='subtitle1'>{content && renderHTML(content)}</Typography>
      </React.Fragment>
    )
  }
}
