/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import 'babel-polyfill';
import { bugsnagClient } from '../utils/bugsnag';

import React from 'react';
import ReactDOM from 'react-dom';
import createPlugin from 'bugsnag-react';
import App from 'components/App';
import '../utils/axios';
import 'images/images';
import '../utils/formsyUtil';

const ErrorBoundary = bugsnagClient.use(createPlugin(React));
ReactDOM.render(
  <ErrorBoundary>
    <App/>
  </ErrorBoundary>,
  document.getElementById('root')
);
