import axios from 'axios';
import { push } from 'connected-react-router';
import { flashMessage } from 'redux-flash';

export const PROCESS_USER_REQUEST = 'PROCESS_USER_REQUEST';
export const PROCESS_USER_SUCCESS = 'PROCESS_USER_SUCCESS';
export const PROCESS_USER_FAILURE = 'PROCESS_USER_FAILURE';
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAILURE = 'CONTACT_US_FAILURE';

export function processUserRequest() {
  return {
    type: PROCESS_USER_REQUEST
  }
}

export function processUserSuccess() {
  return {
    type: PROCESS_USER_SUCCESS
  }
}

export function processUserFailure() {
  return {
    type: PROCESS_USER_FAILURE
  }
}

function fetchUsersRequest() {
  return {
    type: FETCH_USERS_REQUEST
  }
}

function fetchUsersSuccess(users) {
  return {
    type: FETCH_USERS_SUCCESS,
    users
  }
}

function fetchUsersFailure() {
  return {
    type: FETCH_USERS_FAILURE
  }
}

function resetPasswordSuccess(user, organisation, config) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    user,
    organisation,
    config
  }
}

function contactUsRequest() {
  return {
    type: CONTACT_US_REQUEST
  }
}

function contactUsSuccess() {
  return {
    type: CONTACT_US_SUCCESS
  }
}

function contactUsFailure() {
  return {
    type: CONTACT_US_FAILURE
  }
}

export function addUser(userData) {
  return dispatch => {
    dispatch(processUserRequest());
    return axios.post('/users', {user: userData})
      .then(response => {
        if (response.status === 200) {
          dispatch(processUserSuccess());
          dispatch(flashMessage('Invitation successful'));
          dispatch(push('/users'));
        }
      }).catch(error => {
        dispatch(processUserFailure());
        dispatch(flashMessage('Invitation failed', {isError: true}));
      });
  }
}

export function updateUser(id, data) {
  return dispatch => {
    dispatch(processUserRequest());
    return axios.put('/users/' + id, {user: data})
      .then(response => {
        if (response.status === 200) {
          dispatch(processUserSuccess());
          dispatch(flashMessage('User updated successfully'));
          dispatch(push('/users'));
        }
      }).catch(error => {
        dispatch(processUserFailure());
        dispatch(flashMessage('Could not update user at this time', {isError: true}));
      });
  }
}

export function fetchUsers() {
  return dispatch => {
    dispatch(fetchUsersRequest());
    return axios.get('/users')
      .then(response => {
        dispatch(fetchUsersSuccess(response.data.users));
      }).catch(error => {
        dispatch(fetchUsersFailure());
      });
  }
}

export function sendResetInstruction(email) {
  return dispatch => {
    return axios.post('/send_reset_instruction', {email})
      .then(response => {
        dispatch(flashMessage('You will receive an email to reset your password'));
        dispatch(push('/'));
      }).catch(error => {
        dispatch(flashMessage('Email not found', {isError: true}));
      });
  }
}

export function resetPassword(token, password, passwordConfirmation) {
  return dispatch => {
    return axios.put('/password', {reset_password_token: token, password: password, password_confirmation: passwordConfirmation})
      .then(response => {
        dispatch(resetPasswordSuccess(response.data.user, response.data.organisation, response.data.config));
        dispatch(flashMessage('Your password has been reset'));
        dispatch(push('/'));
      }).catch(error => {
        dispatch(flashMessage('Could not reset password', {isError: true}));
      });
  }
}

export function changePassword(data) {
  return dispatch => {
    return axios.post('/change_password', {data})
      .then(response => {
        dispatch(flashMessage('Your password has been updated'));
        dispatch(push('/profile'));
      }).catch(error => {
        dispatch(flashMessage('Could not change password', {isError: true}));
      });
  }
}

export function contactUs(phone_number, message) {
  return dispatch => {
    dispatch(contactUsRequest());
    return axios.post('/send_request', {phone_number, message})
      .then(response => {
        dispatch(contactUsSuccess());
        dispatch(flashMessage('We have successfully received your enquiry'));
        dispatch(push('/'));
      }).catch(error => {
        dispatch(contactUsFailure());
        dispatch(flashMessage('Your request was unsuccessful. Please try again later', {isError: true}));
      });
  }
}
