import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import Formsy from 'formsy-react';
import { Grid, Typography, CircularProgress, Button, Select, MenuItem, Divider } from '@material-ui/core';
import ApproverForm from 'components/pages/forms/ApproverForm';
import { fetchOrder, submitOrder, updateOrder } from 'actions/OrderActions';
import ConfirmDialog from 'components/ConfirmDialog';
import OrderDetail from '../OrderDetail';
import CheckboxInput from 'components/inputs/CheckboxInput';
import SelectInput from 'components/inputs/SelectInput';

export class SubmitOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.dialogRef = React.createRef();
  }

  componentDidMount() {
    this.props.fetchOrder(this.props.match.params.id);
  }

  handleValidSubmit() {
    this.dialogRef.current.handleClickOpen();
  }

  handleSubmit() {
    const { order, submitOrder } = this.props;
    submitOrder(order);
  }

  render() {
    const { currentUser, organisation, order, updateOrder, isFetching, isProcessing, navigateTo } = this.props;

    if (isFetching || !order.id) {
      return <CircularProgress />;
    }

    const site = organisation.sites.filter(site => site.id === order.site_id)[0];
    const approvalRequired = site ? site.approval_required : null;
    const approvers = site && approvalRequired ? site.approvers : [];

    return (
      <React.Fragment>
        <Typography component='h1' variant='h5' align='center'>Summary</Typography>
        <Formsy onValidSubmit={this.handleValidSubmit}>
          <OrderDetail order={order} organisation={organisation} currentUser={currentUser} />

          {approvalRequired &&
            <ApproverForm onChange={(e) => updateOrder('approver_id', e.target.value)} />
          }

          <CheckboxInput name='acceptance1'
                         value='0'
                         label="By submitting the order, you acknowledge responsibility for all information provided. Please ensure all details are correct before proceeding."
                         validations="equals:&quot;1&quot;"
                         validationErrors={{
                           equals: 'Must tick to continue'
                         }}
          />
          <Typography className="top-space">Please check all details carefully before proceeding including:</Typography>
          <ul>
            {['Card Quantity', 'Card Activation Date', 'Card Amount', 'Print Type', 'Delivery Address'].map((listItem, index) =>
              <li key={index}><Typography>{listItem}</Typography></li>
            )}
          </ul>
          <Typography gutterBottom>
            Your order will proceed directly to branding approval and production. Changes to the order, once submitted, will not be accepted.
            For any errors made, 100% of the production fee will be applied including minimum order fee (if applicable).
          </Typography>
          <CheckboxInput name='acceptance2' value='0'
                         label={<span>I agree to the <Link to="/terms_and_conditions" target="_blank">Terms & Conditions</Link></span>}
                         validations="equals:&quot;1&quot;"
                         validationErrors={{
                           equals: 'Must tick to continue'
                         }}
          />
          <div className='button-container'>
            <ConfirmDialog Component={(props) => <Button type='submit' variant='contained' color='primary' disabled={isProcessing || (approvalRequired && approvers.length == 0)}>Submit Order</Button>}
                           label='Submit'
                           message='Your order will be submitted. You can not change any details of your order once this has been done.'
                           buttons={[
                             {label: 'Cancel'},
                             {label: 'OK', callback: this.handleSubmit}
                           ]}
                           ref={this.dialogRef}
            />
            <Button variant='contained' disabled={isProcessing} onClick={() => navigateTo('/')}>Cancel</Button>
          </div>

        </Formsy>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    organisation: state.session.organisation,
    order: state.order.orderDetails,
    isFetching: state.order.isFetching,
    isProcessing: state.order.isProcessing,
    currentUser: state.session.currentUser
  };
}

const mapDispatchToProps = {
  fetchOrder: (orderId) => fetchOrder(orderId),
  submitOrder: (order) => submitOrder(order),
  updateOrder: (key, value) => updateOrder(key, value),
  navigateTo: (path) => push(path)
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitOrderPage);
