import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import store, { history } from 'utils/store';
import ThemeProvider from 'components/ThemeProvider';

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider />
        </ConnectedRouter>
      </Provider>
    )
  }
}
