import React from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import { withStyles} from '@material-ui/core/styles';
import { Typography, Grid, Table, TableBody, TableRow, TableCell, Divider, Avatar, Tooltip, Chip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import Alert from 'components/Alert';
import ConfirmDialog from 'components/ConfirmDialog';
import Currency from 'react-currency-formatter';
import string from 'string';
import { statusColours } from 'utils/commonStyles';
import OrderActionsMenu from 'components/OrderActionsMenu';
import classnames from 'classnames';

const styles = theme => ({
  title: {
    marginTop: '20px'
  },
  card: {
    marginTop: '35px',
    marginBottom: '20px',
    marginLeft: '65px',
    textAlign: 'center',
    position: 'absolute',

    '@media (max-width: 950px)': {
      marginLeft: '0px',
      position: 'relative'
    }
  },
  cardInfo: {
    marginTop: '3em'
  },
  cardImage: {
    position: 'absolute'
  },
  eftposLogo: {
    position: 'relative'
  },
  cardBackImage: {
    marginTop: '35px',
    '@media (max-width: 950px)': {
      marginTop: '0px'
    }
  },
  avatar: {
    margin: 'auto',
    marginTop: '2em'
  },
  divider: {
    width: '100%',
    marginBottom: '2em',
    marginTop: '1em'
  }
});

export class OrderDetail extends React.Component {
  renderOrderDetail() {
    const { currentUser, classes, organisation, order, showActions } = this.props;
    const site = _.find(organisation.sites, ['id', order.site_id]);

    return (
      <React.Fragment>
        <Typography variant='h5' align='center' className={classes.title}>
          Order ID - {order.reference_id}
        </Typography>
        {showActions &&
          <OrderActionsMenu order={order} hideView />
        }
        {order.status == 'shipped' &&
          <Alert type="info" message={this.renderMarkReceivedAlert(order.id)} hideClose />
        }
        <Grid container spacing={8} justify="center">
          <Grid item md={8} xs={12}>
            <Table>
              <TableBody>
                {organisation.sites.length > 1 ?
                  <TableRow>
                    <TableCell>Site</TableCell>
                    <TableCell>{site.name}</TableCell>
                  </TableRow>
                :
                  null
                }
                <TableRow>
                  <TableCell>Job Name</TableCell>
                  <TableCell>{order.job_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Print Location</TableCell>
                  <TableCell>{string(order.print_type).humanize().s}</TableCell>
                </TableRow>
                {order.purchase_order_number ?
                  <TableRow>
                    <TableCell>Purchase Order Number</TableCell>
                    <TableCell>{order.purchase_order_number}</TableCell>
                  </TableRow>
                :
                  null
                }
                <TableRow>
                  <TableCell>Delivery Address</TableCell>
                  <TableCell style={{whiteSpace: 'pre-line'}}>{order.delivery_address}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Delivery Contact Name</TableCell>
                  <TableCell>{order.delivery_contact_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Delivery Contact Phone Number</TableCell>
                  <TableCell>{order.delivery_contact_phone_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>
                    <Chip label={string(order.status).humanize().s} style={{backgroundColor: statusColours[order.status]}} className="status-chip" />
                  </TableCell>
                </TableRow>
                {order.status != 'draft' && order.approver &&
                  <TableRow>
                    <TableCell>Approver</TableCell>
                    <TableCell>
                      {order.approver}
                      {order.user_id == currentUser.id &&
                        <NavLink to={`/orders/${order.id}/change_approver`} style={{paddingLeft: '1em'}}>Change</NavLink>
                      }
                    </TableCell>
                  </TableRow>
                }
                {order.status !== 'draft' ?
                  <TableRow>
                    <TableCell>Paid</TableCell>
                    <TableCell>{order.paid ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                :
                  null
                }
                {order.tracking_url ?
                  <TableRow>
                    <TableCell>Tracking</TableCell>
                    <TableCell style={{wordBreak: 'break-all'}}>
                      <a href={order.tracking_url} target="_blank">{order.tracking_url}</a>
                    </TableCell>
                  </TableRow>
                :
                  null
                }
                {order.status !== 'draft' &&
                  <React.Fragment>
                    <TableRow>
                      <TableCell>Submitted Time</TableCell>
                      <TableCell>{order.submitted_time}</TableCell>
                    </TableRow>
                  </React.Fragment>
                }
              </TableBody>
            </Table>

            {this.renderCosts(site)}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  renderMarkReceivedAlert(id) {
    return (
      <React.Fragment>
        Have you received your order? Click&nbsp;
        <ConfirmDialog Component={(props) => <a href="javascript:void(0)" style={{color: 'white'}} {...props}>here</a>}
                       label='Confirm'
                       message='You acknowledge you have received your card order. They will not become ready for use until payment has been received and order has been processed.'
                       buttons={[
                         {label: 'Cancel'},
                         {label: 'OK', callback: () => this.props.markAsReceived(id)}
                       ]}
        />
        &nbsp;to mark your order as received
      </React.Fragment>
    );
  }

  renderCosts(site) {
    const { order, classes } = this.props;

    return (
      <React.Fragment>
        <Avatar className={classes.avatar}>
          <MoneyIcon />
        </Avatar>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Total Load Amount</TableCell>
              <TableCell align="right">
                <Currency quantity={Number(order.total_load_amount)} currency='AUD' />
              </TableCell>
            </TableRow>
            {order.total_activation_fee > 0 &&
              <TableRow>
                <TableCell>Total Activation Fee</TableCell>
                <TableCell align="right">
                  <Currency quantity={Number(order.total_activation_fee)} currency='AUD' />
                </TableCell>
              </TableRow>
            }
            <TableRow>
              <TableCell>Total Printing Fee</TableCell>
              <TableCell align="right">
                <Currency quantity={Number(order.total_printing_fee)} currency='AUD' />
              </TableCell>
            </TableRow>
            {order.administration_fee > 0 &&
              <TableRow>
                <TableCell>
                  Minimum Order Surcharge
                  <Tooltip title={`Total production/printing fee must be at least $${site.minimum_production_fee}`}>
                    <HelpIcon className="table-icon" color="primary" />
                  </Tooltip>
                </TableCell>
                <TableCell align="right">
                  <Currency quantity={Number(order.administration_fee)} currency='AUD' />
                </TableCell>
              </TableRow>
            }
            <TableRow>
              <TableCell>Delivery Fee</TableCell>
              <TableCell align="right">
                <Currency quantity={Number(order.delivery_fee)} currency='AUD' />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="bold">Total</TableCell>
              <TableCell align="right" className="bold">
                <Currency quantity={Number(order.total_amount)} currency='AUD' />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }

  renderCards() {
    const { order, classes, organisation } = this.props;
    const site = _.find(organisation.sites, ['id', order.site_id]);

    return (
      <React.Fragment>
        {order.cards.map((card, i) => (
          <Grid key={i} container spacing={8} justify='center'>
            <Grid item md={8} xs={12} className={classes.cardInfo}>
              <Typography variant='h6' align='center'>{'Card ' + (i + 1)}</Typography>
              <Table>
                <TableBody>
                  {site && !site.corporate_customer &&
                    <TableRow>
                      <TableCell>Activate Via GiVVPOS?</TableCell>
                      <TableCell>{card.activate_on_site === '1' ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                  }
                  {card.activate_on_site !== '1' &&
                    <TableRow>
                      <TableCell>Card Activation Date</TableCell>
                      <TableCell>{card.activation_date || 'Blank'}</TableCell>
                    </TableRow>
                  }
                  <TableRow>
                    <TableCell>Denominations</TableCell>
                    <TableCell>
                      {card.denominations.map((denomination, index) => (
                        <div key={index}>
                          {denomination.display}
                        </div>
                      ))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Back Design</TableCell>
                    <TableCell>{card.backDesignName}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item md={6} xs={12} style={{textAlign: 'center', display: 'block'}}>
              <div className={classes.card}>
                <img src={card.image} className={classnames(window.EFTPOS_LOGOS[card.eftposLogo] ? classes.cardImage : '', 'card-image')} />
                {window.EFTPOS_LOGOS[card.eftposLogo] &&
                    <img src={require('../images/eftpos_logos/card_'+ window.EFTPOS_LOGOS[card.eftposLogo])} className={classnames(classes.eftposLogo, 'logo-on-card')} />
                }
              </div>
            </Grid>
            <Grid item md={6} xs={12} style={{textAlign: 'center', display: 'block'}}>
              <img src={card.backDesignImage} className={classnames(classes.cardBackImage, 'card-image')} />
            </Grid>
            <Divider className={classes.divider} />
          </Grid>
        ))}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderOrderDetail()}
        {this.renderCards()}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(OrderDetail);
