import React from 'react';
import { connect } from 'react-redux';
import OrderDetailsForm from './forms/OrderDetailsForm';
import { CircularProgress } from '@material-ui/core';
import { resetStore, saveDetails } from 'actions/OrderActions';

export class NewOrderPage extends React.Component {
  constructor(props) {
    super(props);

    props.resetStore();
  }

  render() {
    const { orderDetails, saveDetails} = this.props;

    if (orderDetails.id) {
      return <CircularProgress />
    }

    return (
      <OrderDetailsForm title="New Card Order" onSubmit={() => saveDetails(orderDetails)} />
    )
  }
}

function mapStateToProps(state) {
  return {
    orderDetails: state.order.orderDetails
  }
}

const mapDispatchToProps = {
  resetStore,
  saveDetails: (orderDetails) => saveDetails(orderDetails)
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOrderPage);
