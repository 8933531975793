import React from 'react';
import { connect } from 'react-redux';
import { CssBaseline, AppBar, Toolbar, Typography, Button, Paper } from '@material-ui/core';
import { withStyles} from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import FlashMessage from 'components/FlashMessage';
import { signOutUser } from 'actions/SessionActions';
import PersonIcon from '@material-ui/icons/Person';

const styles = theme => ({
  appBar: {
    position: 'relative',
    marginBottom: '3em'
  },
  brand: {
    flex: 1
  },
  logo: {
    width: '10em',
    verticalAlign: 'middle'
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  footer: {
    margin: '2em 0',
    textAlign: 'center'
  }
});

export class MainLayout extends React.Component {
  renderFooter() {
    const { classes, config } = this.props;

    return (
      <div className={classes.footer}>
        {config.custom_pages.map((custom_page, index) =>
          <NavLink key={index} to={'/' + custom_page.path} exact className='button-link'>
            <Button>{custom_page.name}</Button>
          </NavLink>
        )}
        <NavLink to='/process' exact className='button-link'>
          <Button>The Process</Button>
        </NavLink>
        <NavLink to='/contact' exact className='button-link'>
          <Button>Contact Us</Button>
        </NavLink>
      </div>
    )
  }

  render() {
    const { children, darkMode, classes, signOutUser } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position='static' color='default' className={classes.appBar}>
          <Toolbar className='toolbar'>
            <Typography variant='h6' color='inherit' noWrap className={classes.brand}>
              <NavLink to='/' className='button-link'>
                {darkMode ?
                  <img src={require('images/card-track-dark-mode.png')} className={classes.logo} />
                :
                  <img src={require('images/card-track.png')} className={classes.logo} />
                }
              </NavLink>
            </Typography>
            <NavLink to='/' exact className='button-link'>
              <Button>Orders</Button>
            </NavLink>
            <NavLink to='/users' exact className='button-link'>
              <Button>Users</Button>
            </NavLink>
            <NavLink to='/catalogue' exact className='button-link'>
              <Button>Catalogue</Button>
            </NavLink>
            <NavLink to='/profile' exact className='button-link nav-link'>
              <Button><PersonIcon /></Button>
            </NavLink>
            <Button variant='outlined' onClick={signOutUser}>Log out</Button>
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <FlashMessage />
            {children}
          </Paper>
          {this.renderFooter()}
          <Typography variant="caption" align="center" color="textSecondary" paragraph>
            Powered by Waivpay
          </Typography>
        </main>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    config: state.session.config,
    darkMode: state.session.darkMode
  };
}


const mapDispatchToProps = {
  signOutUser
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MainLayout));
