import React from 'react';
import { Grid, Typography, Select, MenuItem } from '@material-ui/core';

export default class ArtworkGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filteredArtworks: this.props.artworks, siteId: this.props.siteId || -1 };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const siteId = e.target.value;
    if (siteId > 0) {
      this.setState({siteId: siteId, filteredArtworks: _.filter(this.props.artworks, ['site_id', siteId])})
    } else {
      this.setState({ siteId: -1, filteredArtworks: this.props.artworks })
    }
  }

  selectSites() {
    const { organisationAccess, sites } = this.props;
    return (
      organisationAccess && sites && sites.length > 1 &&
        <div style={{ width: 250, margin: 'auto' }}>
          <Select name='site'
                  fullWidth
                  onChange={this.onChange}
                  value={this.state.siteId}>
            <MenuItem key={-1} value={-1}>Show All</MenuItem>
            {
              sites.map(function(site) {
                return <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
              })
            }
          </Select>
        </div>
    )
  }

  render() {
    const { onSelect } = this.props;
    const { filteredArtworks } = this.state;
    const size = filteredArtworks.length > 3 ? 4 : 6;

    return (
      <React.Fragment>
          {this.selectSites()}
          {filteredArtworks.length > 0
            ?
            <Grid container spacing={8} align='center' className="top-space">
              {filteredArtworks.map((artwork, index) =>
                <Grid item key={index} xs={12} md={size}>
                  <div style={{padding: '1em'}}>
                    <img src={artwork.image} className={onSelect ? 'cursor' : '', 'catalogue-card-image'}
                         onClick={() => onSelect ? onSelect({artworkBlobKey: artwork.blob_key, image: artwork.image, type: 'public'}) : null} />
                    <Typography variant='subtitle1'>{artwork.name}</Typography>
                  </div>
                </Grid>
              )}
            </Grid>
            :
            <Typography variant='subtitle1' style={{marginTop: '1em', color: 'grey'}}>Not Available</Typography>
          }
      </React.Fragment>
    );
  }
}
