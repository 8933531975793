import React from 'react';
import { Modal, Typography, AppBar, Tabs, Tab, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Currency from 'react-currency-formatter';

const styles = theme => ({
  paper: {
    position: 'absolute',
    top: '3em',
    left: '25%',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    maxHeight: '90%',
    overflowY: 'scroll'
  }
});

export class PrintPriceModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      tab: 0
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleOpen() {
    this.setState({open: true});
  }

  handleClose() {
    this.setState({open: false});
  }

  handleTabChange(_event, value) {
    this.setState({tab: value});
  }

  renderPricingTable(index) {
    const { printLocations } = this.props;

    const printLocation = printLocations[index];

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Quantity</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {printLocation.mappings.map((priceTier, index) => (
            <TableRow key={index}>
              <TableCell>
                {priceTier.max_quantity ?
                  priceTier.min_quantity + ' - ' + priceTier.max_quantity
                :
                  priceTier.min_quantity + '+'
                }
              </TableCell>
              <TableCell>
                <Currency quantity={parseFloat(priceTier.print_fee)} currency="AUD" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  render() {
    const { printLocations, classes } = this.props;
    const { open, tab } = this.state;

    return (
      <React.Fragment>
        <Typography style={{display: 'inline'}}><a href="javascript:void(0)" onClick={this.handleOpen}>[See Pricing]</a></Typography>
        <Modal open={open} onClose={this.handleClose}>
          <div className={classes.paper}>
            <AppBar position='static'>
              <Tabs variant="fullWidth" value={tab} onChange={this.handleTabChange}>
                {printLocations.map((mapping, index) =>
                  <Tab key={index} label={mapping.description} />
                )}
              </Tabs>
            </AppBar>
            <div className={classes.container}>
              {this.renderPricingTable(tab)}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PrintPriceModal);
