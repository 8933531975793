import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { updateCard } from '../actions/OrderActions';

const styles = theme => ({
  paper: {
    position: 'absolute',
    top: '3em',
    left: '15%',
    width: '70%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    maxHeight: '90%',
    overflowY: 'scroll'
  }
});

export class BackDesignModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleOpen() {
    this.setState({open: true});
  }

  handleClose() {
    this.setState({open: false});
  }

  handleSelect(backDesign) {
    const { cardId, updateCard } = this.props;
    updateCard(cardId, {backDesignId: backDesign.id, backDesignImage: backDesign.image});
    this.handleClose();
  }

  render() {
    const { backDesigns, classes, buttonLabel } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        <Button variant='contained' onClick={this.handleOpen} className='select-artwork'>{buttonLabel}</Button>
        <Modal open={open} onClose={this.handleClose}>
          <div className={classes.paper}>
            { backDesigns &&
              <Grid container spacing={8} align='center'>
                { backDesigns.map((backDesign, index) =>
                  <Grid item key={index} xs={12} md={6}>
                    <div style={{padding: '1em', cursor: 'pointer'}}>
                      <img src={backDesign.image} className='back-card-image' onClick={() => this.handleSelect(backDesign)} />
                      <Typography variant='subtitle1'>{backDesign.name}</Typography>
                    </div>
                  </Grid>
                )}
              </Grid>
            }
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  updateCard: (id, attrs) => updateCard(id, attrs)
};

export default withStyles(styles)(connect(null, mapDispatchToProps)(BackDesignModal));
