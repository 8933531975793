import axios from 'axios';
import { push } from 'connected-react-router';
import { flashMessage } from 'redux-flash';

export const ADD_CARD = 'ADD_CARD';
export const ADD_DENOMINATION = 'ADD_DENOMINATION';
export const UPDATE_DENOMINATION = 'UPDATE_DENOMINATION';
export const REMOVE_DENOMINATION = 'REMOVE_DENOMINATION';
export const RESET_STORE = 'RESET_STORE';
export const UPDATE_CARD = 'UPDATE_CARD';
export const REMOVE_CARD = 'REMOVE_CARD';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const SAVE_DETAILS_REQUEST = 'SAVE_DETAILS_REQUEST';
export const SAVE_DETAILS_SUCCESS = 'SAVE_DETAILS_SUCCESS';
export const SAVE_DETAILS_FAILURE = 'SAVE_DETAILS_FAILURE';
export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';
export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';
export const PROCESS_ORDER_REQUEST = 'PROCESS_ORDER_REQUEST';
export const PROCESS_ORDER_SUCCESS = 'PROCESS_ORDER_SUCCESS';
export const PROCESS_ORDER_FAILURE = 'PROCESS_ORDER_FAILURE';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const SUBMIT_ORDER_REQUEST = 'SUBMIT_ORDER_REQUEST';
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
export const SUBMIT_ORDER_FAILURE = 'SUBMIT_ORDER_FAILURE';
export const ADD_COMMENT_TO_ORDER = 'ADD_COMMENT_TO_ORDER';
export const APPROVE_OR_REJECT = 'APPROVE_OR_REJECT';

function addCardRequest() {
  return {
    type: ADD_CARD
  }
}

function addDenominationRequest(cardId) {
  return {
    type: ADD_DENOMINATION,
    id: cardId
  }
}

function updateDenominationRequest(cardId, id, attrs) {
  return {
    type: UPDATE_DENOMINATION,
    cardId,
    id,
    attrs
  }
}

function removeDenominationRequest(cardId, id) {
  return {
    type: REMOVE_DENOMINATION,
    cardId,
    id
  }
}

function resetStoreRequest() {
  return {
    type: RESET_STORE
  }
}

function updateCardRequest(id, attrs) {
  return {
    type: UPDATE_CARD,
    id,
    attrs
  }
}

function removeCardRequest(id) {
  return {
    type: REMOVE_CARD,
    id
  }
}

function updateOrderRequest(key, value) {
  return {
    type: UPDATE_ORDER,
    key: key,
    value: value
  }
}

function saveDetailsRequest() {
  return {
    type: SAVE_DETAILS_REQUEST
  }
}

function saveDetailsSuccess() {
  return {
    type: SAVE_DETAILS_SUCCESS
  }
}

function saveDetailsFailure() {
  return {
    type: SAVE_DETAILS_FAILURE
  }
}

function fetchOrdersRequest() {
  return {
    type: FETCH_ORDERS_REQUEST
  }
}

function fetchOrdersSuccess(orders) {
  return {
    type: FETCH_ORDERS_SUCCESS,
    orders
  }
}

function fetchOrdersFailure() {
  return {
    type: FETCH_ORDERS_FAILURE
  }
}

function fetchOrderRequest() {
  return {
    type: FETCH_ORDER_REQUEST
  }
}

function fetchOrderSuccess(order) {
  return {
    type: FETCH_ORDER_SUCCESS,
    order
  }
}

function fetchOrderFailure() {
  return {
    type: FETCH_ORDER_FAILURE
  }
}

function processOrderRequest() {
  return {
    type: PROCESS_ORDER_REQUEST
  }
}

function processOrderSuccess() {
  return {
    type: PROCESS_ORDER_SUCCESS
  }
}

function processOrderFailure() {
  return {
    type: PROCESS_ORDER_FAILURE
  }
}

function deleteOrderSuccess(id) {
  return {
    type: DELETE_ORDER_SUCCESS,
    id
  }
}

function submitOrderRequest() {
  return {
    type: SUBMIT_ORDER_REQUEST
  }
}

function submitOrderSuccess() {
  return {
    type: SUBMIT_ORDER_SUCCESS
  }
}

function submitOrderFailure() {
  return {
    type: SUBMIT_ORDER_FAILURE
  }
}

function addCommentToOrderRequest(comment) {
  return {
    type: ADD_COMMENT_TO_ORDER,
    comment
  }
}

function approveOrReject(order, status, comment) {
  return {
    type: APPROVE_OR_REJECT,
    order,
    status,
    comment
  }
}

export function addCard() {
  return dispatch => {
    dispatch(addCardRequest());
  }
}

export function addDenomination(cardId) {
  return dispatch => {
    dispatch(addDenominationRequest(cardId));
  }
}

export function updateDenomination(cardId, id, attrs) {
  return dispatch => {
    dispatch(updateDenominationRequest(cardId, id, attrs));
  }
}

export function removeDenomination(cardId, id) {
  return dispatch => {
    dispatch(removeDenominationRequest(cardId, id));
  }
}

export function resetStore() {
  return dispatch => {
    dispatch(resetStoreRequest());
  }
}

export function updateCard(id, attrs) {
  return dispatch => {
    dispatch(updateCardRequest(id, attrs));
  }
}

export function removeCard(id) {
  return dispatch => {
    dispatch(removeCardRequest(id));
  }
}

export function updateOrder(key, value){
  return dispatch => {
    dispatch(updateOrderRequest(key, value));
  }
}

export function saveDetails(orderDetails) {
  return dispatch => {
    dispatch(saveDetailsRequest());
    return axios.post('/orders', {order: orderDetails})
      .then(response => {
        dispatch(saveDetailsSuccess());
        dispatch(flashMessage('Order successfully saved, add artwork below prior to submitting'));
        dispatch(push('/orders/' + response.data.order_id + '/add_artwork'));
      }).catch(error => {
        dispatch(saveDetailsFailure());
        dispatch(flashMessage('Order cannot be saved, please try again', {isError: true}));
      });
  }
}

export function updateDetails(orderDetails, redirectPath = null) {
  return dispatch => {
    dispatch(saveDetailsRequest());
    return axios.put('/orders/' + orderDetails.id, {order: orderDetails})
      .then(response => {
        dispatch(saveDetailsSuccess());
        dispatch(flashMessage('Order successfully saved'));
        dispatch(push(redirectPath || '/orders/' + orderDetails.id + '/add_artwork'));
      }).catch(error => {
        dispatch(saveDetailsFailure());
        dispatch(flashMessage('Order cannot be saved, please try again', {isError: true}));
      });
  }
}

export function fetchOrders(scope, searchParam='') {
  return dispatch => {
    dispatch(fetchOrdersRequest());
    return axios.get('/orders' + searchParam, {params: {scope}})
      .then(response => {
        dispatch(fetchOrdersSuccess(response.data.orders));
      }).catch(error => {
        dispatch(fetchOrdersFailure());
      });
  }
}

export function fetchOrder(orderId, onSuccess = null) {
  return dispatch => {
    dispatch(fetchOrderRequest());
    return axios.get('/orders/' + orderId)
      .then(response => {
        dispatch(fetchOrderSuccess(response.data.order));
        if(onSuccess){
          onSuccess(response.data.order);
        }
      }).catch(error => {
        dispatch(fetchOrderFailure());
        dispatch(flashMessage('Order not found', {isError: true}));
        dispatch(push('/'));
      });
  }
}

export function duplicateOrder(orderId) {
  return dispatch => {
    dispatch(processOrderRequest());
    return axios.post('/orders/' + orderId + '/duplicate')
      .then(response => {
        dispatch(processOrderSuccess());
        dispatch(flashMessage('Order successfully duplicated'));
        dispatch(push('/orders/' + response.data.order_id + '/edit'));
      }).catch(error => {
        dispatch(processOrderFailure());
        dispatch(flashMessage('Order could not be duplicated, please try again', {isError: true}));
      });
  }
}

export function deleteOrder(orderId) {
  return dispatch => {
    dispatch(processOrderRequest());
    return axios.delete('/orders/' + orderId)
      .then(response => {
        dispatch(deleteOrderSuccess(orderId));
        dispatch(flashMessage('Order successfully deleted'));
      }).catch(error => {
        dispatch(processOrderFailure());
        dispatch(flashMessage('Order could not be deleted, please try again', {isError: true}));
      });
  }
}

export function submitOrder(orderDetails) {
  return dispatch => {
    dispatch(submitOrderRequest());
    return axios.post('/orders/' + orderDetails.id + '/submit', {order: orderDetails})
      .then(response => {
        dispatch(submitOrderSuccess());
        dispatch(flashMessage('Order successfully submitted. You will receive a confirmation email with invoice shortly'));
        dispatch(push('/'));
      }).catch(error => {
        dispatch(submitOrderFailure());
        dispatch(flashMessage('Order could not be submitted, please try again', {isError: true}));
      });
  }
}

export function markAsReceived(orderId) {
  return dispatch => {
    return axios.post('/orders/' + orderId + '/deliver')
      .then(response => {
        dispatch(flashMessage('Order has been marked as received, you will be notified when your cards are ready for use'));
        dispatch(fetchOrders());
      }).catch(error => {
        dispatch(flashMessage('Order could not be marked as received', {isError: true}));
      });
  }
}

export function addCommentToOrder(orderId, comment) {
  return dispatch => {
    return axios.post('/orders/' + orderId + '/comments', {body: comment})
      .then(response => {
        dispatch(flashMessage('Comment successfully added'));
        dispatch(addCommentToOrderRequest(response.data.comment));
        dispatch(fetchOrder(orderId));
      }).catch(error => {
        dispatch(flashMessage('Comment could not be added', {isError: true}));
      });
  }
}

export function markAsApprovedOrRejected(orderId, status, comment) {
  return dispatch => {
    return axios.post('/orders/' + orderId + '/process_approval', {status: status, comment: comment})
      .then(response => {
        dispatch(flashMessage('Order has been marked as ' + status));
        dispatch(approveOrReject(response.data.order, status, comment));
        dispatch(fetchOrder(orderId));
      }).catch(error => {
        dispatch(flashMessage('Order could not be marked as ' + status, {isError: true}));
      });
  }
}
