import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import Formsy from 'formsy-react';
import { Typography, Button, Avatar } from '@material-ui/core';
import { withStyles}  from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/LockOutlined';
import TextInput from '../inputs/TextInput';
import qs from 'query-string';
import { resetPassword } from 'actions/UserActions';
import { NavLink } from 'react-router-dom';
import { commonTheme } from 'utils/commonStyles';

export class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.currentUser) {
      this.props.navigateTo('/');
    }
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    const queryString = qs.parse(this.props.location.search);
    const token = queryString.reset_password_token;
    this.props.resetPassword(token, data.password, data.password_confirmation);
  }

  render() {
    const { classes, navigateTo } = this.props;

    return (
      <React.Fragment>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
        <Formsy onValidSubmit={this.handleSubmit} className={classes.form}>
          <Typography component='h1' variant='h5' align='center'>Reset your password</Typography>

          <TextInput type='password' name='password' label='Password' required
            validations="minLength:8"
            validationErrors={{
              isDefaultRequiredValue: "can't be blank",
              minLength: 'must be more than 8 characters'
            }}
          />
          <TextInput type='password' name='password_confirmation' label='Password Confirmation' required
            validations="minLength:8,equalsField:password"
            validationErrors={{
              isDefaultRequiredValue: "can't be blank",
              minLength: 'must be more than 8 characters',
              equalsField: 'must match password'
            }}
          />

          <div className='button-container'>
            <Button fullWidth type='submit' variant='contained' color='primary'>Reset password</Button>
            <Typography variant='body2' className="top-space cursor" onClick={() => navigateTo('/login')}>Login</Typography>
          </div>
        </Formsy>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.session.currentUser
  };
}

const mapDispatchToProps = {
  navigateTo: (path) => push(path),
  resetPassword: (token, password, passwordConfirm) => resetPassword(token, password, passwordConfirm)
};

export default withStyles(commonTheme)(withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage)));
