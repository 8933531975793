import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Formsy from 'formsy-react';
import ApproverForm from 'components/pages/forms/ApproverForm';
import { CircularProgress, Button } from '@material-ui/core';
import { fetchOrder, updateDetails } from 'actions/OrderActions';

export class ChangeApproverPage extends React.Component {
  constructor(props) {
    super(props);

    props.fetchOrder(props.match.params.order_id);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(data) {
    const { updateDetails, order } = this.props;

    updateDetails(Object.assign(data, {id: order.id}), '/orders/' + order.id);
  }

  render() {
    const { isFetching, isProcessing, order, updateDetails, navigateTo } = this.props;

    if (isFetching || !order.id) {
      return <CircularProgress />
    }

    return (
      <React.Fragment>
        <Formsy onValidSubmit={this.handleSubmit}>
          <ApproverForm />

          <div className='button-container'>
            <Button type='submit' variant='contained' color='primary' disabled={isProcessing}>Update</Button>
            <Button variant='contained' disabled={isProcessing} onClick={() => navigateTo('/orders/' + order.id)}>Cancel</Button>
          </div>
        </Formsy>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.order.isFetching,
    order: state.order.orderDetails,
    isProcessing: state.order.isProcessing
  }
}

const mapDispatchToProps = {
  fetchOrder: (orderId) => fetchOrder(orderId),
  updateDetails: (data, redirectPath) => updateDetails(data, redirectPath),
  navigateTo: (path) => push(path)
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeApproverPage);
