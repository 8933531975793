import React from 'react';
import { connect } from 'react-redux';
import Catalogue from 'components/Catalogue';
import { fetchArtworks } from 'actions/ArtworkActions';
import { CircularProgress } from '@material-ui/core';

export class CataloguePage extends React.Component {
  componentDidMount() {
    this.props.fetchArtworks();
  }

  render() {
    const { artworks, pastOrderArtworks, userArtworks, isFetching, organisationAccess, sites } = this.props;

    if (isFetching) {
      return <CircularProgress />;
    }

    return (
      <Catalogue artworks={artworks} pastOrderArtworks={pastOrderArtworks} userArtworks={userArtworks} organisationAccess={organisationAccess} sites={sites} />
    )
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.artwork.isFetching,
    artworks: state.artwork.artworks.publicCatalogue,
    pastOrderArtworks: state.artwork.artworks.pastOrderCatalogue,
    userArtworks: state.artwork.artworks.userCatalogue,
    organisationAccess: state.session.organisation_access,
    sites: state.session.organisation.sites
  };
}

const mapDispatchToProps = {
  fetchArtworks
};

export default connect(mapStateToProps, mapDispatchToProps)(CataloguePage);
