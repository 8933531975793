import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Formsy from 'formsy-react';
import { Grid, Button, Typography, CssBaseline, Paper, IconButton, Divider, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import renderHTML from 'react-render-html';
import { addCard, addDenomination, updateDenomination, removeDenomination, updateCard, removeCard, updateOrder } from 'actions/OrderActions';
import TextInput from 'components/inputs/TextInput';
import DateInput from 'components/inputs/DateInput';
import RadioInput from 'components/inputs/RadioInput';
import SelectInput from 'components/inputs/SelectInput';
import CheckboxInput from 'components/inputs/CheckboxInput';
import PrintPriceModal from 'components/PrintPriceModal';
import ConfirmDialog from 'components/ConfirmDialog';
import CheckoutAmount from 'utils/CheckoutAmount';
import TotalCheckoutAmount from 'utils/TotalCheckoutAmount';
import { minQuantity } from 'utils/cardMapping';
import { commonTheme } from 'utils/commonStyles';

const requiredValidation = {isDefaultRequiredValue: "can't be blank"};

export class OrderDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.renderDeliveryFields = this.renderDeliveryFields.bind(this);
  }

  componentDidMount() {
    const { printLocationId, updateOrder } = this.props;

    updateOrder('print_location_id', printLocationId);
  }

  renderCards(site, printLocation) {
    const { addDenomination, updateDenomination, removeDenomination, updateCard, removeCard, orderDetails } = this.props;
    const { cards } = orderDetails;

    return (
      cards.map((card, index) =>
        <React.Fragment key={index}>
          <Typography variant="h5" style={{display: 'inline', verticalAlign: 'middle'}}>Card Design {index + 1}</Typography>
          {index !== 0 &&
            <IconButton aria-label="Delete" onClick={() => removeCard(card.uid)}>
              <DeleteIcon />
            </IconButton>
          }
          <Grid container spacing={8}>
            <Grid item xs={11} md={7}>
              {site && !site.corporate_customer &&
                <CheckboxInput name="activate_on_site" label="Activate Via GiVVPOS?" className='activate_on_site_checkbox'
                  value={card.activate_on_site}
                  onChange={(value) => updateCard(card.uid, {activate_on_site: value})}
                  hint="Card activation fee will be charged at time of sale. Cards will contain panel to write activation date when paid."
                />
              }
              {card.activate_on_site !== '1' &&
                <React.Fragment>
                  <DateInput name={'activation_date_' + card.uid} label='Activation Date' required
                    value={orderDetails.activation_date_update_required ? null : card.activation_date}
                    onChange={(e) => updateCard(card.uid, {activation_date: e.toString()})}
                    startDate={printLocation.minimum_activation_date}
                    hint="Date cards will be activated for all denominations set up below"
                    validationErrors={requiredValidation}
                  />
                  <ConfirmDialog Component={(props) => <Typography><a href="javascript:void(0)" {...props}>Why is my activation date so important?</a></Typography>}
                                 label='Activation Date'
                                 message='Your activation date is a vital component of your order. In order for our system
                                         to upload your funds and activate your cards on your required date, payment must be received
                                         by Waivpay at least 3 days before your activation date. Cards cannot be activated until your
                                         invoice is paid in full. Un-activated cards cannot be used or handed out to clients as when
                                         use is attempted on an un-activated card, significant charges are incurred.'
                                 buttons={[{label: 'Ok'}]}
                  />
                </React.Fragment>
              }
            </Grid>
            <Grid item xs={11} md={5}>
              <CheckoutAmount site={site} card={card} printLocation={printLocation}/>
            </Grid>
          </Grid>

          <Typography variant="h5" className="top-space">Denominations</Typography>
          {card.denominations && card.denominations.filter((denom) => !denom._destroy).map((denomination, index) =>
            <Grid container spacing={8} justify="center" key={index}>
              <React.Fragment>
                <Grid item xs={5}>
                  <TextInput type='number' name={'quantity_' + denomination.uid} label='Quantity' required
                    value={denomination.quantity}
                    onChange={(e) => updateDenomination(card.uid, denomination.uid, {quantity: e.target.value})}
                    validationErrors={requiredValidation}
                  />
                </Grid>
                <Grid item md={5}>
                  {card.activate_on_site === '1' ?
                    <TextInput type='number' name={'print_amount_' + denomination.uid} label='Print Amount'
                      value={denomination.print_amount}
                      onChange={(e) => updateDenomination(card.uid, denomination.uid, {print_amount: e.target.value})}
                      hint='Optional. Enter an amount if you wish to print the value on the back of the card'
                      inputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      validationErrors={{isLessThanEqualTo: `must be less than or equal to ${MAXIMUM_CARD_VALUE}`, isGreaterThanEqualTo: `must be greater than or equal to ${MINIMUM_CARD_VALUE}`}}
                      validations={denomination.print_amount ? `isGreaterThanEqualTo:${MINIMUM_CARD_VALUE},isLessThanEqualTo:${MAXIMUM_CARD_VALUE}` : null}
                    />
                  :
                    <TextInput type='number' name={'amount_' + denomination.uid} label='Amount' required
                      value={denomination.amount}
                      onChange={(e) => updateDenomination(card.uid, denomination.uid, {amount: e.target.value})}
                      inputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      inputFieldProps={{
                        step: '0.01'
                      }}
                      validationErrors={_.merge({}, requiredValidation, {isLessThanEqualTo: `must be less than or equal to ${MAXIMUM_CARD_VALUE}`, isGreaterThanEqualTo: `must be greater than or equal to ${MINIMUM_CARD_VALUE}`})}
                      validations={denomination.amount ? `isGreaterThanEqualTo:${MINIMUM_CARD_VALUE},isLessThanEqualTo:${MAXIMUM_CARD_VALUE}` : null}
                    />
                  }
                </Grid>
                <Grid item xs={1}>
                  {card.denominations.filter((denom) => !denom._destroy).length > 1 &&
                    <IconButton aria-label="Delete" onClick={() => removeDenomination(card.uid, denomination.uid)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                </Grid>
              </React.Fragment>
            </Grid>
          )}
          <Typography align="right"><a href="javascript:void(0)" onClick={() => addDenomination(card.uid)} className="top-space">Add Denomination</a></Typography>
          <Grid container spacing={8} justify="center">
            <Grid item xs={5}>
              <TextInput disabled label="Total Quantity" type="number" name={'total_' + card.uid}
                value={_.sumBy(card.denominations, (d) => parseInt(d.quantity) || 0)}
                validationErrors={{isGreaterThanEqualTo: `total quantity must be greater than or equal to ${minQuantity(printLocation)}`}}
                validations={`isGreaterThanEqualTo:${minQuantity(printLocation)}`}
              />
            </Grid>
            <Grid item xs={6} />
          </Grid>
          <Divider light className="card-divider" />
        </React.Fragment>
      )
    )
  }

  renderDeliveryFields() {
    const { orderDetails, updateOrder } = this.props;
    const { delivery_street_address_1, delivery_street_address_2, delivery_suburb, delivery_state, delivery_postcode,
            delivery_contact_name, delivery_contact_phone_number } = orderDetails;

    const states = ['NSW', 'VIC', 'QLD', 'SA', 'WA', 'TAS', 'ACT', 'NT'];

    return (
      <Grid container spacing={8} justify="center">
        <Grid item xs={12} md={9}>
          <Typography component="h1" variant="h5" align="center" className="top-space">Delivery</Typography>
          <TextInput name='delivery_street_address_1' label='Street Address Line 1' required
            validationErrors={requiredValidation} value={delivery_street_address_1}
            onChange={ (e) => updateOrder('delivery_street_address_1', e.target.value)}
          />
          <TextInput name='delivery_street_address_2' label='Street Address Line 2 (optional)'
            value={delivery_street_address_2}
            onChange={ (e) => updateOrder('delivery_street_address_2', e.target.value)}
          />
          <TextInput name='delivery_suburb' label='Suburb' required
            validationErrors={requiredValidation} value={delivery_suburb}
            onChange={ (e) => updateOrder('delivery_suburb', e.target.value)}
          />
          <Grid container>
            <Grid item xs={12} md={6}>
              <SelectInput name='delivery_state' label='State' required
                validationErrors={requiredValidation} value={delivery_state}
                onChange={ (e) => updateOrder('delivery_state', e.target.value)}
                options={states}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput name='delivery_postcode' label='Postcode' required
                validations='isLength:4,isNumeric'
                validationErrors={requiredValidation} value={delivery_postcode}
                onChange={ (e) => updateOrder('delivery_postcode', e.target.value)}
              />
            </Grid>
          </Grid>
          <TextInput name='delivery_contact_name' label='Delivery Contact Name' required
            validationErrors={requiredValidation} value={delivery_contact_name}
            onChange={ (e) => updateOrder('delivery_contact_name', e.target.value)}
          />
          <TextInput name='delivery_contact_phone_number' label='Delivery Contact Phone Number' required
            validationErrors={requiredValidation} value={delivery_contact_phone_number}
            onChange={ (e) => updateOrder('delivery_contact_phone_number', e.target.value)}
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const { title, addCard, classes, updateOrder, orderDetails, printLocationId, onSubmit, currentUser, sites, print_locations, isProcessing } = this.props;
    const { cards } = orderDetails;
    const site = _.find(sites, ['id', currentUser.site_id]);
    const printLocation = _.find(print_locations, ['id', printLocationId]);

    return (
      <React.Fragment>
        <Formsy onValidSubmit={onSubmit} className={classes.form}>
          <Typography component="h1" variant="h5" align="center">{title}</Typography>
          <Grid container spacing={8} justify="center">
            <Grid item xs={12} md={9}>
              {currentUser.organisation_access &&
                <SelectInput name='site_id' label='Site' required
                  onChange={ (e) => updateOrder('site_id', e.target.value) }
                  options={ sites.map((site) => { return {value: site.id, label: site.name}}) }
                  value={orderDetails.site_id}
                  validationErrors={requiredValidation}
                />
              }
              <TextInput name='job_name' label='Job Name' required
                validationErrors={_.merge(requiredValidation, {maxLength: 'must be less than or equal to ' + window.MAXIMUM_JOB_NAME_LENGTH + ' characters'})}
                validations={'maxLength:' + window.MAXIMUM_JOB_NAME_LENGTH}
                onChange={ (e) => updateOrder('job_name', e.target.value)}
                value={orderDetails.job_name}
              />
              <TextInput name='purchase_order_number' label='Purchase Order Number'
                value={orderDetails.purchase_order_number}
                onChange={ (e) => updateOrder('purchase_order_number', e.target.value)}
                hint="Optional, will be referenced on your invoice if supplied"
              />
              <RadioInput name='print_location_id' label={<React.Fragment>Print Type <PrintPriceModal printLocations={print_locations} /></React.Fragment>} containerClass="top-space"
                value={printLocationId.toString()}
                options={print_locations.map(function(mapping){return {value: mapping.id, label: mapping.description}})}
                onChange={ (value) => updateOrder('print_location_id', value)} style={{marginTop: '1em'}}
              />
              {printLocation.selected_notice &&
                <div>
                  <Typography component="h5" className="bold">Note:</Typography>
                  <Typography component="span" style={{display: 'inline-block'}}>
                    {renderHTML(printLocation.selected_notice)}
                  </Typography>
                </div>
              }
            </Grid>
          </Grid>

          <Typography component="h1" variant="h5" align="center" gutterBottom>Cards</Typography>
          {this.renderCards(site, printLocation)}
          <div className="center">
            <Button variant="contained" color="secondary" size="small" onClick={() => addCard()}>Add another card</Button>
          </div>
          <TotalCheckoutAmount cards={cards} printLocation={printLocation} site={site}/>
          {this.renderDeliveryFields()}
          <div className="button-container">
            <Button type="submit" variant="contained" color="primary" disabled={isProcessing}>Save Order</Button>
            <NavLink to="/" className="button-link">
              <Button variant='contained' disabled={isProcessing}>Cancel</Button>
            </NavLink>
          </div>
        </Formsy>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.session.currentUser,
    orderDetails: state.order.orderDetails,
    printLocationId: state.order.orderDetails.print_location_id || state.session.organisation.print_locations[0].id,
    isProcessing: state.order.isProcessing,
    sites: state.session.organisation.sites,
    print_locations: state.session.organisation.print_locations
  }
}

const mapDispatchToProps = {
  addCard,
  addDenomination: (id) => addDenomination(id),
  updateDenomination: (cardId, id, attrs) => updateDenomination(cardId, id, attrs),
  removeDenomination: (cardId, id) => removeDenomination(cardId, id),
  updateCard: (id, attrs) => updateCard(id, attrs),
  updateOrder: (key, value) => updateOrder(key, value),
  removeCard: (id) => removeCard(id)
};

export default withStyles(commonTheme)(connect(mapStateToProps, mapDispatchToProps)(OrderDetailsForm));
