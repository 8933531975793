import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import { TextField, FormHelperText } from '@material-ui/core';

class TextInput extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.value || this.props.value === 0) {
      this.props.setValue(this.props.value, false);
    }
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    const { onChange, setValue } = this.props;
    if (onChange) onChange(event);
    setValue(event.target.value);
  }

  render() {
    const { type, name, label, disabled, multiline, inputLabelProps, inputProps, inputFieldProps,
            hint, getValue, getErrorMessage, isPristine } = this.props;
    const errorMessage = getErrorMessage();
    const isError = !isPristine() && errorMessage != null;

    return (
      <div className="input-container text-field">
        <TextField
          error={isError}
          type={type || "text"}
          multiline={multiline}
          rows="4"
          id={name}
          name={name}
          label={label}
          onChange={this.changeValue}
          value={getValue() || ''}
          disabled={disabled}
          fullWidth
          InputLabelProps={inputLabelProps}
          inputProps={inputFieldProps}
          InputProps={inputProps}
        />
        {!isPristine() && errorMessage &&
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText>{hint}</FormHelperText>
        }
      </div>
    );
  }
}

TextInput.propTypes = {
  ...propTypes
};

export default withFormsy(TextInput);
