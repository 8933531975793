import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import UsersForm from 'components/pages/forms/UsersForm';
import { updateUser, fetchUsers } from 'actions/UserActions';
import _ from 'lodash';

export class EditUserPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.fetchUsers();
  }

  user() {
    const { users, match } = this.props;
    return _.find(users, (u) => { return u.id == match.params.id });
  }

  handleSubmit(data, _resetForm, _invalidateForm) {
    const { updateUser, match } = this.props;
    updateUser(match.params.id, data);
  }

  render() {
    const { config, isFetching } = this.props;

    return (
      <React.Fragment>
        <Typography component="h1" variant="h5" align="center" gutterBottom>Edit User</Typography>
        {isFetching ?
          <Grid container justify="center" className="top-space">
            <CircularProgress />
          </Grid>
        :
          <UsersForm handleSubmit={this.handleSubmit} roles user={this.user()} cancelRoute={"/users"} submitLabel="Update User" />
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    config: state.session.config,
    isFetching: state.user.isFetching,
    users: state.user.users
  };
}

const mapDispatchToProps = {
  fetchUsers,
  updateUser: (id, data) => updateUser(id, data)
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserPage);
