import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Formsy from 'formsy-react';
import { Grid, Button } from '@material-ui/core';
import { withStyles}  from '@material-ui/core/styles';
import TextInput from 'components/inputs/TextInput';
import CheckboxGroupInput from 'components/inputs/CheckboxGroupInput';
import _ from 'lodash';
import string from 'string';
import { commonTheme } from 'utils/commonStyles';

const requiredValidation = {isDefaultRequiredValue: "can't be blank"};

export class UsersForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.user
    };
  }

  render() {
    const { config, classes, handleSubmit, submitLabel, isProcessing, roles, cancelRoute} = this.props;

    return (
      <React.Fragment>
        <Formsy onValidSubmit={handleSubmit} className={classes.form}>
          <Grid container spacing={8} justify="center">
            <Grid item xs={12} md={9}>
              <TextInput name='first_name' label='First Name' required
                value={this.state.first_name}
                validationErrors={requiredValidation}
              />
              <TextInput name='last_name' label='Last Name' required
                value={this.state.last_name}
                validationErrors={requiredValidation}
              />
              <TextInput name='email' label='Email' required
                value={this.state.email}
                validations={{isEmail: true}}
                validationErrors={_.merge(requiredValidation, {isEmail: 'not a valid email'})}
              />
              { roles && <CheckboxGroupInput name="roles" value={this.state.roles || ['creator']}
                options={config.roles.map((role, index) =>{ return {label: string(role).humanize().s, value: role, disabled: role === 'admin'} })}
              />}
            </Grid>
          </Grid>
          <div className='button-container'>
            <Button type='submit' variant='contained' color='primary' disabled={isProcessing}>{submitLabel}</Button>
            <NavLink to={cancelRoute} className="button-link">
              <Button variant='contained' disabled={isProcessing}>Cancel</Button>
            </NavLink>
          </div>
        </Formsy>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    config: state.session.config,
    isProcessing: state.user.isProcessing
  };
}

export default withStyles(commonTheme)(connect(mapStateToProps, {})(UsersForm));
