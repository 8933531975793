import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import UsersForm from 'components/pages/forms/UsersForm';
import { addUser } from 'actions/UserActions';

export class NewUserPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(userData, _resetForm, _invalidateForm) {
    this.props.addUser(userData);
  }

  render() {
    return (
      <React.Fragment>
        <Typography component="h1" variant="h5" align="center" gutterBottom>New User</Typography>
        <UsersForm handleSubmit={this.handleSubmit} roles submitLabel="Send Invitation" cancelRoute={"/users"} />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  addUser: (userData) => addUser(userData)
};

export default connect(null, mapDispatchToProps)(NewUserPage);
