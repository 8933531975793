import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Switch, withRouter } from 'react-router-dom';
import { AppRoute } from './AppRoute';
import MainLayout from './layouts/MainLayout';
import DashboardPage from './pages/DashboardPage';
import NewOrderPage from './pages/NewOrderPage';
import EditOrderPage from './pages/EditOrderPage';
import ChangeApproverPage from './pages/ChangeApproverPage';
import UsersPage from './pages/UsersPage';
import NewUserPage from './pages/NewUserPage';
import EditUserPage from './pages/EditUserPage';
import ArtworkForm from './pages/forms/ArtworkForm';
import CataloguePage from './pages/CataloguePage';
import SubmitOrderPage from './pages/SubmitOrderPage';
import ViewOrderPage from './pages/ViewOrderPage';
import ProfilePage from './pages/ProfilePage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import UpdateDetailsPage from './pages/UpdateDetailsPage';
import ProcessPage from './pages/ProcessPage';
import ContactUsPage from './pages/ContactUsPage';
import CustomPage from './pages/CustomPage';

export class Router extends React.Component {
  constructor(props) {
    super(props);

    if (!props.isFetching && !props.currentUser) {
      props.navigateTo('/login');
    }
  }

  render() {
    if (!this.props.currentUser) {
      return null;
    }

    return (
      <React.Fragment>
        <Switch>
          <AppRoute exact path='/' layout={MainLayout} component={DashboardPage} />
          <AppRoute exact path='/orders/new' layout={MainLayout} component={NewOrderPage} />
          <AppRoute exact path='/users' layout={MainLayout} component={UsersPage} />
          <AppRoute exact path='/users/new' layout={MainLayout} component={NewUserPage} />
          <AppRoute exact path='/users/:id/edit' layout={MainLayout} component={EditUserPage} />
          <AppRoute exact path='/orders/:order_id/edit' layout={MainLayout} component={EditOrderPage} />
          <AppRoute exact path='/orders/:order_id/change_approver' layout={MainLayout} component={ChangeApproverPage} />
          <AppRoute exact path='/orders/:id/add_artwork' layout={MainLayout} component={ArtworkForm} />
          <AppRoute exact path='/catalogue' layout={MainLayout} component={CataloguePage} />
          <AppRoute exact path='/orders/:id/submit' layout={MainLayout} component={SubmitOrderPage} />
          <AppRoute exact path='/orders/:id' layout={MainLayout} component={ViewOrderPage} />
          <AppRoute exact path='/profile' layout={MainLayout} component={ProfilePage} />
          <AppRoute exact path='/change_password' layout={MainLayout} component={ChangePasswordPage} />
          <AppRoute exact path='/edit_profile' layout={MainLayout} component={UpdateDetailsPage} />
          <AppRoute exact path='/process' layout={MainLayout} component={ProcessPage} />
          <AppRoute exact path='/contact' layout={MainLayout} component={ContactUsPage} />
          {this.props.config.custom_pages.map((custom_page, index) =>
            <AppRoute key={index} exact path={'/' + custom_page.path} layout={MainLayout} component={() => <CustomPage content={custom_page.content} name={custom_page.name} />} />
          )}
        </Switch>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.session.isFetching,
    currentUser: state.session.currentUser,
    config: state.session.config
  };
}

const mapDispatchToProps = {
  navigateTo: (path) => push(path)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Router));
