import React from 'react';
import { connect } from 'react-redux';
import { Typography, Stepper, Step, StepLabel, StepContent, Chip } from '@material-ui/core';
import { statusColours } from 'utils/commonStyles';
import _ from 'lodash';
import string from 'string';

const steps = [
  {name: 'draft', content: 'Your order has not been submitted and can be openly edited and modified'},
  {name: 'submitted', content: 'Your order has been submited to us. You will have recieved an invoice to your email. It is now having proofs built for our confirmation before actual printing is done'},
  {name: 'proofs_confirmed', content: 'We have confirmed the proofs from the printers and the order is now processing with the printers'},
  {name: 'shipped', content: 'Your order has been shipped. Once it has arrived you need to notify us through the system so they can be used'},
  {name: 'received', content: 'You have marked your order as received. The cards are in the final process of being uploaded and activated (if required)'},
  {name: 'completed', content: 'Your order is fully complete! No more action required'}
];

export class ProcessPage extends React.Component {
  getSteps() {
    const { organisation, currentUser } = this.props;

    const site = _.find(organisation.sites, ['id', currentUser.site_id]);
    if (site.approval_required) {
      steps.splice(1, 0, {name: 'awaiting_approval', content: 'Your order is awaiting confirmation by an approver. Once approved it will move to Submitted'});
    }
    return steps;
  }

  render() {
    return (
      <React.Fragment>
        <Typography component='h1' variant='h5' align='center'>The Process</Typography>

        <Stepper activeStep={0} orientation="vertical">
          {this.getSteps().map((step, index) =>
            <Step active key={index}>
              <StepLabel>
                <Chip label={string(step.name).humanize().s} style={{backgroundColor: statusColours[step.name]}} className="status-chip" />
              </StepLabel>
              <StepContent>
                <Typography>{step.content}</Typography>
              </StepContent>
            </Step>
          )}
        </Stepper>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    organisation: state.session.organisation,
    currentUser: state.session.currentUser
  };
}

export default connect(mapStateToProps, null)(ProcessPage);
