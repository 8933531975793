import axios from 'axios';
import { push } from 'connected-react-router';
import { signOutSuccess } from 'actions/SessionActions';
import { flashMessage } from 'redux-flash';
import store from './store';

axios.defaults.baseURL = '/api/v1';
axios.defaults.headers.common['X-CSRF-Token'] = getToken();

function getToken() {
  const meta = document.querySelector('meta[name="csrf-token"]');
  return meta && meta.getAttribute('content');
}

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 401) {
    // If any request returns unauthorized, clear current user and redirect to login
    store.dispatch(signOutSuccess());
    store.dispatch(flashMessage('Your session has expired', {props: {type: 'info'}}));
    store.dispatch(push('/login'));
    return false;
  }

  if (error.response.status === 403) {
    // If any request returns forbidden, return to home page
    store.dispatch(push('/'));
    store.dispatch(flashMessage("You don't have permission for this action", {isError: true}));
    return false;
  }
  return Promise.reject(error);
});
