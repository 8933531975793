import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

export default class ConfirmDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      buttonDisabled: false
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClickOpen() {
    this.setState({open: true, buttonDisabled: false});
  };

  handleClose(callback) {
    this.setState({open: false, buttonDisabled: true});
    if (callback) callback();
  };

  render() {
    const { Component, label, message, buttons } = this.props;

    return (
      <React.Fragment>
        <Component onClick={this.handleClickOpen} />
        <Dialog
          open={this.state.open}
          onClose={() => this.handleClose(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{label}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            {buttons.map((button, index) =>
              <Button key={index} onClick={() => this.handleClose(button.callback)} color="primary" disabled={this.state.buttonDisabled}>
                {button.label}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ConfirmDialog.defaultProps = {
  Component: (props) => <Button {...props}>Open</Button>,
  buttons: []
};
