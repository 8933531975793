import React from 'react';
import { NavLink } from 'react-router-dom';
import { IconButton, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export default class SimpleMenu extends React.Component {
  constructor() {
    super();
    this.state = { anchorEl: null }
  }

  render() {
    const { options } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;

    return (
      <React.Fragment>
        <IconButton aria-owns={open ? 'simple-menu' : undefined} aria-haspopup='true' onClick={(e) => this.setState({anchorEl: e.currentTarget})}>
          <MoreVertIcon />
        </IconButton>

        <Menu id="simple-menu" anchorEl={anchorEl} open={open} onClose={() => this.setState({anchorEl: null})}
              MenuListProps={{disablePadding: true}}
              PaperProps={{style: {maxHeight: ITEM_HEIGHT * 6, width: 200, boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'}}}>
          {options.map((option, i) =>
            React.cloneElement(option, {key: i})
          )}
        </Menu>
      </React.Fragment>
    )
  }
}
