import React from 'react';
import { propTypes, withFormsy } from 'formsy-react';
import { FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';

class CheckboxInput extends React.Component {
  constructor(props) {
    super(props);
    this.props.setValue(this.props.value || '0', false);

    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    const { onChange, setValue } = this.props;
    const value = event.target.checked ? '1' : '0';
    if (onChange) onChange(value);
    setValue(value);
  }

  render() {
    const { name, label, hint, getValue, getErrorMessage, isPristine } = this.props;
    const errorMessage = getErrorMessage();
    const isError = !isPristine() && errorMessage != null;

    return (
      <div className="input-container">
        <FormControlLabel
          control={
            <Checkbox id={name} checked={getValue() == '1'} onChange={this.changeValue} value={name} />
          }
          label={label}
        />
        {!isPristine() && errorMessage &&
          <FormHelperText error={isError}>{errorMessage}</FormHelperText>
        }
        {hint &&
          <FormHelperText style={{marginTop: '0'}}>{hint}</FormHelperText>
        }
      </div>
    );
  }
}

CheckboxInput.propTypes = {
  ...propTypes
};

export default withFormsy(CheckboxInput);
