import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as FlashReducer } from 'redux-flash';
import OrderReducer from './OrderReducer';
import SessionReducer from './SessionReducer';
import UserReducer from './UserReducer';
import ArtworkReducer from './ArtworkReducer';

export default (history) => combineReducers({
  flash: FlashReducer,
  router: connectRouter(history),
  order: OrderReducer,
  session: SessionReducer,
  user: UserReducer,
  artwork: ArtworkReducer
})
