import React from 'react';
import { connect } from 'react-redux';
import { formatLocalDateTime } from 'utils/dates';
import { CircularProgress, Typography, Grid } from '@material-ui/core';
import { fetchOrder, addCommentToOrder, markAsReceived } from 'actions/OrderActions';
import OrderDetail from 'components/OrderDetail';
import ApproveOrder from 'components/ApproveOrder';
import { AddComment } from 'components/AddComment';

export class ViewOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: ''
    }
  }

  componentDidMount() {
    this.props.fetchOrder(this.props.match.params.id);
  }

  setComment = (comment) => {
    this.setState({ comment });
  };

  render() {
    const { organisation, order, currentUser, isFetching, addCommentToOrder, markAsReceived } = this.props;

    if (isFetching || !order.id) {
      return <CircularProgress />;
    }

    return (
      <React.Fragment>
        <OrderDetail order={order} organisation={organisation} currentUser={currentUser} markAsReceived={markAsReceived} showActions />
        {order.comments.length > 0 &&
          <Grid container justify="center">
            <Grid item md={8} xs={12}>
              <Typography variant="h6" gutterBottom>Comments</Typography>
            </Grid>
            {order.comments.map((comment, i) =>
              <Grid item md={8} xs={12} key={i}>
                <Typography variant="body1" gutterBottom>{comment.body}</Typography>
                <Typography variant="caption" gutterBottom>
                  {formatLocalDateTime(comment.created_at)} - {comment.author}
                </Typography>
              </Grid>
            )}
          </Grid>
        }
        {order.status === 'awaiting_approval' && order.approver_id === currentUser.id ?
          <ApproveOrder order={order} currentUser={currentUser} />
          :
          <Grid container justify="center">
            <Grid item md={8} xs={12}>
              <AddComment
                order={order}
                addCommentToOrder={addCommentToOrder}
                setComment={this.setComment}
                comment={this.state.comment}
                />
            </Grid>
          </Grid>
        }

      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    organisation: state.session.organisation,
    order: state.order.orderDetails,
    isFetching: state.order.isFetching,
    currentUser: state.session.currentUser
  };
}

const mapDispatchToProps = {
  addCommentToOrder: (orderId, comment) => addCommentToOrder(orderId, comment),
  fetchOrder: (orderId) => fetchOrder(orderId),
  markAsReceived: (orderId) => markAsReceived(orderId),
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrderPage);
