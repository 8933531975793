import React from 'react';
import { Modal, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Catalogue from './Catalogue';

const styles = theme => ({
  paper: {
    position: 'absolute',
    top: '3em',
    left: '15%',
    width: '70%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    maxHeight: '90%',
    overflowY: 'scroll'
  }
});

export class ArtworkModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleOpen() {
    this.setState({open: true});
  }

  handleClose() {
    this.setState({open: false});
  }

  handleSelect(attrs) {
    this.props.onSelect(attrs);
    this.handleClose();
  }

  render() {
    const { artworks, classes, buttonLabel, pastOrderArtworks, userArtworks, sites, siteId } = this.props;
    const { open, tab } = this.state;

    return (
      <React.Fragment>
        <Button variant="contained" onClick={this.handleOpen} className='select-artwork'>{buttonLabel}</Button>
        <Modal open={open} onClose={this.handleClose}>
          <div className={classes.paper}>
            <Catalogue
              artworks={artworks}
              pastOrderArtworks={pastOrderArtworks}
              userArtworks={userArtworks}
              onSelect={this.handleSelect}
              sites={sites}
              siteId={siteId}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ArtworkModal);
