import React from 'react';
import { connect } from 'react-redux';
import ConfirmDialog from 'components/ConfirmDialog';
import SimpleMenu from 'components/SimpleMenu';
import { MenuItem } from '@material-ui/core';
import { push } from 'connected-react-router';
import { markAsReceived, duplicateOrder, deleteOrder } from 'actions/OrderActions';

export class OrderActionsMenu extends React.Component {
  render() {
    const { order, hideView, currentUser } = this.props;
    const options = [];

    if (!hideView) options.push(<MenuItem onClick={() => this.props.navigateTo('/orders/' + order.id)}>View</MenuItem>);

    if (order.status === 'draft') {
      options.push(<MenuItem onClick={() => this.props.navigateTo('/orders/' + order.id + '/edit')}>Edit</MenuItem>);
      options.push(<MenuItem onClick={() => this.props.navigateTo('/orders/' + order.id + '/add_artwork')}>Add Artwork</MenuItem>);
    }

    if (order.can_submit) {
      options.push(<MenuItem onClick={() => this.props.navigateTo('/orders/' + order.id + '/submit')}>Submit</MenuItem>);
    }

    if(order.status === 'shipped') {
      options.push(
        <ConfirmDialog Component={(props) => <MenuItem {...props}>Mark As Received</MenuItem>}
                       label='Confirm'
                       message='You acknowledge you have received your card order. They will not become ready for use until payment has been received and order has been processed.'
                       buttons={[
                         {label: 'Cancel'},
                         {label: 'OK', callback: () => this.props.markAsReceived(order.id)}
                       ]}
        />
      );
    }

    options.push(
      <ConfirmDialog Component={(props) => <MenuItem {...props}>Duplicate</MenuItem>}
                     label='Confirm'
                     message='Are you sure you want to duplicate this order? It will create a new order with matching details and artwork.'
                     buttons={[
                       {label: 'No'},
                       {label: 'Yes', callback: () => this.props.duplicateOrder(order.id)}
                     ]}
      />
    );

    if (order.status === 'draft' && currentUser.id === order.user_id) {
      options.push(
        <ConfirmDialog Component={(props) => <MenuItem {...props}>Delete</MenuItem>}
                       label='Confirm'
                       message='Are you sure you want to delete this order? It will completely remove the order. This can not be undone.'
                       buttons={[
                         {label: 'No'},
                         {label: 'Yes', callback: () => this.props.deleteOrder(order.id)}
                       ]}
        />
      );
    }

    return (<SimpleMenu options={options} />);
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.session.currentUser
  };
}

const mapDispatchToProps = {
  duplicateOrder: (orderId) => duplicateOrder(orderId),
  navigateTo: (path) => push(path),
  markAsReceived: (orderId) => markAsReceived(orderId),
  deleteOrder: (orderId) => deleteOrder(orderId)
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderActionsMenu);
